import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function UsdtIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="UsdtIcon">
            
<circle cx="12" cy="12" r="12" fill="black"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.6242 8.04076V10.035L13.6263 10.0372C17.2648 10.2077 20 10.9436 20 11.8221C20 12.7026 17.2648 13.4364 13.6263 13.607V19.9998H10.3843V13.607C6.74159 13.4343 4 12.7005 4 11.8199C4 10.9393 6.73946 10.2055 10.3843 10.035V8.04076H5.89981V4.99976H18.1087V8.04076H13.6242ZM13.6242 13.0587V13.0609V13.0631C16.8365 12.9163 19.2346 12.3465 19.2346 11.6645C19.2346 10.9825 16.8386 10.4127 13.6242 10.2659V12.4933C13.5309 12.5019 13.0305 12.5451 12.0297 12.5451C11.1964 12.5451 10.5921 12.5084 10.3822 12.4933V10.2638C7.16353 10.4084 4.75908 10.9803 4.75908 11.6623C4.75908 12.3465 7.16141 12.9163 10.3822 13.0609V13.0587C10.59 13.0695 11.1773 13.0933 12.0148 13.0933C13.0623 13.0933 13.533 13.0652 13.6242 13.0587Z" fill="white"/>


        </SvgIcon>
    )
}
        