import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function UserAvatarIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="UserAvatarIcon">
            
<path d="M11.9999 0C5.37223 0 0 5.29115 0 11.8181C0 18.3452 5.37217 23.6364 11.9999 23.6364C18.6275 23.6364 24 18.3452 24 11.8181C24 5.29115 18.6275 0 11.9999 0ZM12.055 4.65897C14.4752 4.65897 16.4372 6.5912 16.4372 8.97475C16.4372 11.3583 14.4752 13.2905 12.055 13.2905C9.63473 13.2905 7.67275 11.3583 7.67275 8.97475C7.67275 6.5912 9.63473 4.65897 12.055 4.65897ZM12.0548 23.5133C8.75748 23.5133 5.77502 22.1892 3.62345 20.0523C4.66441 16.4302 8.04368 13.7752 12.055 13.7752C16.0661 13.7752 19.4454 16.4301 20.4865 20.0522C18.3348 22.1891 15.3521 23.5133 12.0548 23.5133Z" />


        </SvgIcon>
    )
}
        