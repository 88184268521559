/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatisticsDto } from '../models/StatisticsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StatisticService {

    /**
     * Get statistics
     * @returns StatisticsDto
     * @throws ApiError
     */
    public static statisticsControllerGet(): CancelablePromise<StatisticsDto> {
        return __request({
            method: 'GET',
            path: `/api/statistics`,
        });
    }

}