import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function MoreIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="MoreIcon">
            
<circle cx="12" cy="12" r="12" />
<path d="M17.1 12.6H13.38V16.24H12.12V12.6H8.4V11.42H12.12V7.76H13.38V11.42H17.1V12.6Z" />


        </SvgIcon>
    )
}
        