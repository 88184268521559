import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ChevronUpIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ChevronUpIcon">
            
  <path d="M16.9997 13.9108L12.7097 9.6708C12.6167 9.57707 12.5061 9.50268 12.3843 9.45191C12.2624 9.40114 12.1317 9.375 11.9997 9.375C11.8677 9.375 11.737 9.40114 11.6151 9.45191C11.4933 9.50268 11.3827 9.57707 11.2897 9.6708L7.0497 13.9108C6.95598 14.0038 6.88158 14.1144 6.83081 14.2362C6.78004 14.3581 6.75391 14.4888 6.75391 14.6208C6.75391 14.7528 6.78004 14.8835 6.83081 15.0054C6.88158 15.1272 6.95598 15.2378 7.0497 15.3308C7.23707 15.517 7.49052 15.6216 7.7547 15.6216C8.01889 15.6216 8.27234 15.517 8.4597 15.3308L11.9997 11.7908L15.5397 15.3308C15.726 15.5155 15.9774 15.6197 16.2397 15.6208C16.3713 15.6216 16.5018 15.5963 16.6236 15.5466C16.7454 15.4968 16.8563 15.4235 16.9497 15.3308C17.0468 15.2412 17.1251 15.1332 17.1802 15.0131C17.2353 14.8931 17.2661 14.7633 17.2708 14.6312C17.2754 14.4992 17.2539 14.3675 17.2073 14.2439C17.1608 14.1202 17.0902 14.007 16.9997 13.9108Z" />

        </SvgIcon>
    )
}
        