import { ThemeProvider } from "@peersyst/react-components";
import { PropsWithChildren } from "react";

import { CSSReset } from "./reset";
import { theme } from "./theme";

const StylesProvider = ({ children }: PropsWithChildren<any>): JSX.Element => (
    <ThemeProvider theme={theme}>
        <CSSReset />
        {children}
    </ThemeProvider>
);

export default StylesProvider;
