import { lazy } from "react";
import { Route } from "react-router-dom";

export enum ProfileRoutes {
    WALLET = "/wallet",
}

const Wallet = lazy(() => import("./Wallet"));

export const WalletRouter = (
    <>
        <Route path={ProfileRoutes.WALLET} element={<Wallet />} />
    </>
);
