import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function LinkedinFilledIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="LinkedinFilledIcon">
            
<path d="M0 1.71921C0 0.76997 0.789019 0 1.76237 0H22.2376C23.2109 0 24 0.769972 24 1.71921V22.2808C24 23.2303 23.2109 24 22.2376 24L1.76237 24C0.789019 24 0 23.2303 0 22.2808V1.71921ZM7.41507 20.0905V9.25314H3.81294V20.0905H7.41507ZM5.61399 7.77349C6.87012 7.77349 7.65196 6.9413 7.65196 5.90131C7.62855 4.8379 6.87013 4.02881 5.63782 4.02881C4.40564 4.02881 3.6 4.8379 3.6 5.90131C3.6 6.9413 4.38167 7.77349 5.59051 7.77349H5.61399ZM12.9771 20.0905V14.0385C12.9771 13.7146 13.0005 13.391 13.0956 13.1594C13.356 12.5123 13.9487 11.8421 14.9438 11.8421C16.2473 11.8421 16.7687 12.8359 16.7687 14.2928V20.0905H20.3705V13.8765C20.3705 10.5478 18.5934 8.99884 16.2235 8.99884C14.3127 8.99884 13.4559 10.049 12.9772 10.7875V10.8248H12.9531C12.9611 10.8125 12.9691 10.8 12.9772 10.7875V9.25314H9.3751C9.42237 10.27 9.3751 20.0905 9.3751 20.0905H12.9771Z" />


        </SvgIcon>
    )
}
        