import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function SwapIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="SwapIcon">
            
<path d="M4.57275 11.0266H14.3246C14.4762 11.0266 14.6277 10.901 14.6277 10.7252V8.69127C14.6277 8.54061 14.5014 8.38994 14.3246 8.38994H4.57275L7.14966 5.4771C7.3265 5.30133 7.17492 5 6.92228 5H4.19379C4.118 5 4.04221 5.02511 3.96642 5.10044L0.0757914 9.51993C-0.0252638 9.62037 -0.0252638 9.79614 0.0757914 9.89659L3.96642 14.3161C4.01695 14.3914 4.09274 14.4165 4.19379 14.4165H6.92228C7.17492 14.4165 7.30124 14.1152 7.14966 13.9394L4.57275 11.0266Z" />
<path d="M23.9243 14.489L20.0336 10.0946C19.9831 10.0193 19.9073 9.99414 19.8063 9.99414H17.0778C16.8251 9.99414 16.6988 10.2955 16.8504 10.4712L19.4273 13.3841H9.67548C9.5239 13.3841 9.37231 13.5096 9.37231 13.6854V15.7194C9.37231 15.87 9.49863 16.0207 9.67548 16.0207H19.4273L16.8504 18.9084C16.6736 19.0842 16.8251 19.3855 17.0778 19.3855H19.8063C19.8821 19.3855 19.9579 19.3604 20.0336 19.2851L23.9243 14.8656C24.0253 14.7652 24.0253 14.6145 23.9243 14.489Z" />


        </SvgIcon>
    )
}
        