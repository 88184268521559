import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function FacebookFilledIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="FacebookFilledIcon">
            
<path d="M24.9995 12.0738C24.9995 5.40562 19.6266 0 12.9988 0C6.37094 0 0.998047 5.40562 0.998047 12.0738C0.998047 18.1002 5.38653 23.0952 11.1236 24.0009V15.5639H8.07659V12.0738H11.1236V9.4138C11.1236 6.3878 12.9153 4.71633 15.6565 4.71633C16.9695 4.71633 18.3428 4.95215 18.3428 4.95215V7.92344H16.8296C15.3388 7.92344 14.8739 8.85413 14.8739 9.80896V12.0738H18.2022L17.6701 15.5639H14.8739V24.0009C20.611 23.0952 24.9995 18.1002 24.9995 12.0738Z" />


        </SvgIcon>
    )
}
        