import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function LogoIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="LogoIcon">
            
<g clipPath="url(#clip0_309_17)">
<path d="M11.0823 12.6261C10.0318 13.841 8.65084 16.0179 8.71845 16.0344C12.6716 17.013 17.1756 19.915 19.1085 21.0777C19.1266 21.0893 17.3317 18.329 16.859 17.4291C15.5825 15.0037 14.3302 12.7844 14.3005 12.7684L12.8613 12.1088C12.8613 12.1088 11.8382 11.7503 11.0823 12.6261Z" fill="url(#paint0_linear_309_17)"/>
<path d="M11.6964 7.98709L15.9431 15.8247C16.6204 17.0863 18.3097 20.5887 19.3438 21.2292C21.2679 22.4199 24.0424 22.0373 23.9995 21.9686C23.1573 20.6157 21.8594 18.2001 21.5708 17.6003C21.1728 16.773 18.4499 11.3108 16.2867 6.86947C14.908 4.03943 14.0454 3.35775 12.8085 2.64419C11.0406 1.62498 7.96094 0.885032 6.73228 1.01477C6.70951 1.01705 6.68802 1.02638 6.67081 1.04146C6.6536 1.05654 6.64151 1.07661 6.63625 1.09887C6.63099 1.12114 6.6328 1.1445 6.64144 1.16569C6.65008 1.18687 6.66512 1.20484 6.68445 1.21707C8.61623 2.46333 10.6305 6.04212 11.6964 7.98709Z" fill="url(#paint1_linear_309_17)"/>
<path d="M19.6857 21.2245C18.6297 20.5704 16.8348 17.063 16.1432 15.775L11.9471 7.77577C10.9103 5.88413 8.71793 2.4785 6.83453 1.05908C6.80924 1.05908 6.80649 1.05908 6.73228 1.06953C6.70951 1.07181 6.68802 1.08114 6.67081 1.09622C6.6536 1.11129 6.64151 1.13136 6.63625 1.15363C6.63099 1.1759 6.6328 1.19925 6.64144 1.22044C6.65008 1.24163 6.66512 1.25959 6.68445 1.27183C8.61623 2.51808 10.6305 6.09687 11.6964 8.04184L15.9431 15.8794C16.6204 17.1411 18.3097 20.6435 19.3438 21.2839C20.4697 21.9815 21.8176 22.1377 22.7318 22.1338C21.894 22.0585 20.5521 21.7611 19.6857 21.2245Z" fill="url(#paint2_linear_309_17)"/>
<path d="M20.0127 21.227C18.9682 20.5788 17.0941 17.0374 16.4086 15.7615L12.2773 7.83924C11.4489 6.32636 9.86617 3.82945 8.31921 2.18244C7.86732 1.70142 6.93772 1.00435 6.9773 1.03569C9.1625 2.75857 11.1256 6.04819 12.1916 7.99371L16.2756 15.8286C16.9528 17.0902 18.8043 20.5926 19.8411 21.233C20.9533 21.9224 22.1154 22.0824 23.0296 22.0829C22.1198 22.0587 21.055 21.8724 20.0127 21.227Z" fill="url(#paint3_linear_309_17)"/>
<path d="M0.020516 21.7994C0.0090788 21.8154 0.00213971 21.8342 0.000420976 21.8538C-0.00129776 21.8734 0.00226653 21.8931 0.0107436 21.9108C0.0192208 21.9285 0.0323 21.9437 0.0486186 21.9547C0.0649372 21.9656 0.0838989 21.972 0.103527 21.9732C0.961117 22.032 4.30847 22.4679 6.5492 19.1832C8.77343 15.9244 10.4523 13.5001 10.8624 12.9008C11.687 11.6914 12.8602 12.107 12.8602 12.107L8.39082 9.90808C7.47111 9.42981 7.07145 9.559 6.7471 10.073L5.5047 12.2544C5.41234 12.4231 1.22884 20.1024 0.020516 21.7994Z" fill="url(#paint4_linear_309_17)"/>
<path d="M12.1267 11.7464C11.7968 11.6788 11.3021 11.7695 10.6424 12.7359C10.2334 13.3357 8.55337 15.7594 6.33024 19.0188C4.3072 21.9874 1.27265 21.9171 0.0918167 21.8329L0.0764241 21.8549C0.0649869 21.8709 0.0580478 21.8897 0.0563291 21.9093C0.0546103 21.9289 0.0581763 21.9486 0.0666534 21.9663C0.0751305 21.984 0.0882081 21.9992 0.104527 22.0101C0.120845 22.0211 0.139807 22.0275 0.159435 22.0287C1.01703 22.0875 4.36438 22.5234 6.6051 19.2387C8.82824 15.9793 10.4417 13.5479 10.8623 12.9558C11.7968 11.6364 12.8963 12.1312 12.8963 12.1312C12.8963 12.1312 12.4406 11.9008 12.1267 11.7464Z" fill="url(#paint5_linear_309_17)"/>
<path opacity="0.5" d="M6.7461 10.0698L5.51359 12.1665C5.51359 12.1665 1.22894 20.1025 0.0206129 21.7996C-0.0261148 21.865 0.0898792 21.7759 0.145403 21.7006C0.200926 21.6253 0.325167 21.4488 0.365298 21.384C1.88257 19.002 5.3767 12.63 5.46246 12.4683C5.46246 12.4683 5.47016 12.4645 5.47126 12.4623L6.72906 10.3255" fill="url(#paint6_linear_309_17)"/>
<path opacity="0.6" d="M11.2956 11.7031C10.5864 12.2402 6.87078 17.9701 6.87078 17.9701C4.37552 21.9161 2.02374 21.7188 0.590576 21.7468C0.608168 21.7655 0.632907 21.7391 0.662043 21.7408C1.54877 21.8024 4.15562 22.2735 6.79436 18.2329C8.97902 14.8883 10.7211 12.426 11.3022 11.801" fill="url(#paint7_linear_309_17)"/>
</g>
<defs>
<linearGradient id="paint0_linear_309_17" x1="18.7736" y1="20.1431" x2="9.64328" y2="13.7133" gradientUnits="userSpaceOnUse">
<stop offset="0.14" stopColor="#7DB6FD"/>
<stop offset="0.66" stopColor="#2849B8"/>
<stop offset="0.82" stopColor="#2432B1"/>
<stop offset="0.95"/>
</linearGradient>
<linearGradient id="paint1_linear_309_17" x1="9.67846" y1="1.42645" x2="18.9995" y2="23.4632" gradientUnits="userSpaceOnUse">
<stop offset="0.02" stopColor="#F8CD86"/>
<stop offset="0.15" stopColor="#F59000"/>
<stop offset="0.3" stopColor="#E52D21"/>
<stop offset="0.63" stopColor="#7DB6FD"/>
<stop offset="0.87" stopColor="#2C4ACA"/>
<stop offset="1" stopColor="#0B0050"/>
</linearGradient>
<linearGradient id="paint2_linear_309_17" x1="16.8597" y1="7.99663" x2="11.6825" y2="16.6631" gradientUnits="userSpaceOnUse">
<stop offset="0.02" stopColor="#FF9318"/>
<stop offset="0.16" stopColor="#790300"/>
<stop offset="0.3"/>
<stop offset="0.63" stopColor="#7DB6FD"/>
<stop offset="0.93" stopColor="#2C4ACA"/>
<stop offset="1" stopColor="#0B0050"/>
</linearGradient>
<linearGradient id="paint3_linear_309_17" x1="17.1154" y1="8.02253" x2="12.035" y2="16.5266" gradientUnits="userSpaceOnUse">
<stop offset="0.14" stopColor="white" stopOpacity="0.3"/>
<stop offset="0.56" stopColor="#DFF4FF" stopOpacity="0.3"/>
<stop offset="1" stopColor="#002A94" stopOpacity="0.5"/>
</linearGradient>
<linearGradient id="paint4_linear_309_17" x1="9.59952" y1="10.2345" x2="-0.364215" y2="25.2411" gradientUnits="userSpaceOnUse">
<stop offset="0.14" stopColor="#7DB6FD"/>
<stop offset="0.68" stopColor="#2849B8"/>
<stop offset="0.87" stopColor="#2432B1"/>
</linearGradient>
<linearGradient id="paint5_linear_309_17" x1="5.4611" y1="15.1355" x2="8.22298" y2="20.3179" gradientUnits="userSpaceOnUse">
<stop offset="0.14" stopColor="#002255"/>
<stop offset="0.68" stopColor="#2849B8"/>
<stop offset="0.87" stopColor="#2432B1"/>
</linearGradient>
<linearGradient id="paint6_linear_309_17" x1="1.72695" y1="12.7419" x2="5.69714" y2="20.4541" gradientUnits="userSpaceOnUse">
<stop offset="0.14" stopColor="#7DB6FD"/>
<stop offset="0.31" stopColor="#DFF4FF"/>
<stop offset="0.84" stopColor="#3F53C2" stopOpacity="0.4"/>
</linearGradient>
<linearGradient id="paint7_linear_309_17" x1="4045.09" y1="4627.48" x2="4521.78" y2="5577.47" gradientUnits="userSpaceOnUse">
<stop offset="0.14" stopColor="#7DB6FD"/>
<stop offset="0.31" stopColor="#DFF4FF"/>
<stop offset="0.84" stopColor="#3F53C2" stopOpacity="0.4"/>
</linearGradient>
<clipPath id="clip0_309_17">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>


        </SvgIcon>
    )
}
        