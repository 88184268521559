import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function SymbolMoreIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="SymbolMoreIcon">
            
<path d="M24.39 14.4241H14.1279V24.726H10.6521V14.4241H0.389999V11.0845H10.6521V0.725997H14.1279V11.0845H24.39V14.4241Z" />


        </SvgIcon>
    )
}
        