import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function House2Icon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="House2Icon">
            
<path d="M17.0492 7.04191L9.54916 0.375247C9.39709 0.241648 9.20159 0.167969 8.99916 0.167969C8.79674 0.167969 8.60124 0.241648 8.44916 0.375247L0.949163 7.04191C0.821693 7.15467 0.731803 7.30376 0.691579 7.46912C0.651355 7.63449 0.662726 7.8082 0.724163 7.96691C0.784595 8.12353 0.890917 8.25824 1.0292 8.3534C1.16749 8.44857 1.3313 8.49975 1.49916 8.50025H2.3325V16.0002C2.3325 16.2213 2.42029 16.4332 2.57657 16.5895C2.73285 16.7458 2.94482 16.8336 3.16583 16.8336H14.8325C15.0535 16.8336 15.2655 16.7458 15.4218 16.5895C15.578 16.4332 15.6658 16.2213 15.6658 16.0002V8.50025H16.4992C16.667 8.49975 16.8308 8.44857 16.9691 8.3534C17.1074 8.25824 17.2137 8.12353 17.2742 7.96691C17.3356 7.8082 17.347 7.63449 17.3067 7.46912C17.2665 7.30376 17.1766 7.15467 17.0492 7.04191ZM9.8325 15.1669H8.16583V12.6669C8.16583 12.4459 8.25363 12.2339 8.40991 12.0777C8.56619 11.9214 8.77815 11.8336 8.99916 11.8336C9.22018 11.8336 9.43214 11.9214 9.58842 12.0777C9.7447 12.2339 9.8325 12.4459 9.8325 12.6669V15.1669ZM13.9992 15.1669H11.4992V12.6669C11.4992 12.0039 11.2358 11.368 10.7669 10.8991C10.2981 10.4303 9.6622 10.1669 8.99916 10.1669C8.33612 10.1669 7.70024 10.4303 7.2314 10.8991C6.76256 11.368 6.49916 12.0039 6.49916 12.6669V15.1669H3.99916V8.50025H13.9992V15.1669ZM3.69083 6.83358L8.99916 2.11691L14.3075 6.83358H3.69083Z" />

        </SvgIcon>
    )
}
        