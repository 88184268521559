/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedUserBalanceInProjectDto } from '../models/PaginatedUserBalanceInProjectDto';
import type { TransferMoneyRequest } from '../models/TransferMoneyRequest';
import type { UserBalanceInProjectDto } from '../models/UserBalanceInProjectDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { PaginatedTransferDto } from '../models/PaginatedTranferDto';
import { AcceptTransferRequest } from '../models/AcceptTransferRequest';
import { TransferDto } from '../models/TransferDto';

export class BalanceService {

    /**
     * Show all user wallets
     * @param page
     * @param pageSize
     * @returns PaginatedUserBalanceInProjectDto
     * @throws ApiError
     */
    public static balanceControllerGetUserWallets(
        page: number,
        pageSize: number,
    ): CancelablePromise<PaginatedUserBalanceInProjectDto> {
        return __request({
            method: 'GET',
            path: `/api/balance/me`,
            query: {
                'page': page,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Show all user wallets for specific project
     * @param projectId
     * @returns UserBalanceInProjectDto
     * @throws ApiError
     */
    public static balanceControllerGetUserWalletsForProject(
        projectId: number,
    ): CancelablePromise<Array<UserBalanceInProjectDto>> {
        return __request({
            method: 'GET',
            path: `/api/balance/me/project/${projectId}`,
        });
    }

    /**
     * Transfer money from one project to another
     * @param id
     * @param requestBody
     * @returns UserBalanceInProjectDto
     * @throws ApiError
     */
    public static balanceControllerTransferMoney(
        id: number,
        requestBody: TransferMoneyRequest,
    ): CancelablePromise<UserBalanceInProjectDto> {
        return __request({
            method: 'POST',
            path: `/api/balance/${id}/transfer`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }


    /**
     * Find all transfers
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterSentCurrency
     * @param filterStatus
     * @param filterUserId
     * @returns PaginatedTransferDto
     * @throws ApiError
     */
        public static balanceControllerGetAllTransfers(
            page: number,
            pageSize: number,
            filterSentCurrency?: 'usd' | 'ars' | 'usdt',
            filterStatus?: 'pending' | 'sent' | 'completed' | 'rejected',
            filterUserId?: number,
        ): CancelablePromise<PaginatedTransferDto> {
            return __request({
                method: 'GET',
                path: `/api/balance/transfers`,
                query: {
                    'page': page,
                    'pageSize': pageSize,
                    'filter[sentCurrency]': filterSentCurrency,
                    'filter[status]': filterStatus,
                    'filter[userId]': filterUserId,
                },
            });
        }
    
    /**
     * Accept transfer
     * @param id
     * @param requestBody
     * @returns TransferDto
     * @throws ApiError
     */
    public static balanceControllerAcceptTransfer(
        id: number,
        requestBody: AcceptTransferRequest,
    ): CancelablePromise<TransferDto> {
        return __request({
            method: 'PUT',
            path: `/api/balance/transfers/${id}/accept`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    
    /**
     * Reject transfer
     * @param id
     * @returns TransferDto
     * @throws ApiError
     */
    public static balanceControllerRejectTransfer(
        id: number,
    ): CancelablePromise<TransferDto> {
        return __request({
            method: 'POST',
            path: `/api/balance/transfers/${id}/reject`,
        });
    }
}