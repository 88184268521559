import { atom } from "recoil";

export enum AdminRole {
    ADMIN = "admin",
    ADMIN_L2 = "admin_l2",
    ADMIN_L3 = "admin_l3",
}

export enum UserRole {
    USER = "user",
}

export type UserType = UserRole | AdminRole;

interface AuthState {
    token: string | undefined;
    isLogged: boolean;
    role?: UserType;
}

export const authState = atom<AuthState>({
    key: "authState",
    default: { token: undefined, isLogged: false },
});

export const admins = {
    [AdminRole.ADMIN]: {
        level: 1,
    },
    [AdminRole.ADMIN_L2]: {
        level: 2,
    },
    [AdminRole.ADMIN_L3]: {
        level: 3,
    },
};
