import { FC, PropsWithChildren } from "react";
import StylesProvider from "./module/common/style";
import { ToastProvider } from "@peersyst/react-components";
import { RecoilRoot } from "recoil";
import QueryClientProvider from "./query/QueryClientProvider";

const Providers: FC<PropsWithChildren> = ({ children }): JSX.Element => (
    <RecoilRoot>
        <StylesProvider>
            <ToastProvider>
                <QueryClientProvider>
                    {children}
                    {/*{process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}*/}
                </QueryClientProvider>
            </ToastProvider>
        </StylesProvider>
    </RecoilRoot>
);

export default Providers;
