/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthCredentialsDto } from '../models/AuthCredentialsDto';
import type { LoginRequest } from '../models/LoginRequest';
import type { RecoverPasswordRequest } from '../models/RecoverPasswordRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { ValidateEmailRequest } from '../models/ValidateEmailRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AuthenticateService {

    /**
     * Authenticate user with email
     * @param requestBody
     * @returns AuthCredentialsDto
     * @throws ApiError
     */
    public static authControllerLogin(
        requestBody: LoginRequest,
    ): CancelablePromise<AuthCredentialsDto> {
        return __request({
            method: 'POST',
            path: `/api/auth/login`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Authenticate user with Google
     * @returns any
     * @throws ApiError
     */
    public static authGoogleControllerGoogleAuth(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/auth/google`,
        });
    }

    /**
     * Google auth callback
     * @returns any
     * @throws ApiError
     */
    public static authGoogleControllerGoogleAuthRedirect(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/auth/google/callback`,
        });
    }

    /**
     * Authenticate user with Twitter
     * @returns any
     * @throws ApiError
     */
    public static authTwitterControllerTwitterAuth(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/auth/twitter`,
        });
    }

    /**
     * Twitter auth callback
     * @returns any
     * @throws ApiError
     */
    public static authTwitterControllerTwitterAuthRedirect(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/auth/twitter/callback`,
        });
    }

    /**
     * Verify user email
     * @param requestBody
     * @returns AuthCredentialsDto
     * @throws ApiError
     */
    public static authValidateControllerVerifyEmail(
        requestBody: ValidateEmailRequest,
    ): CancelablePromise<AuthCredentialsDto> {
        return __request({
            method: 'POST',
            path: `/api/auth/verify-email`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Request Password Reset
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authRecoverControllerRequestResetPassword(
        requestBody: RecoverPasswordRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/auth/recover-password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Password Reset
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authRecoverControllerResetPassword(
        requestBody: ResetPasswordRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/auth/reset-password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}