import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function GraphIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="GraphIcon">
            
<path d="M23.9986 9.1284H19.1826V23.9997H23.9986V9.1284Z" />
<path d="M24 0V5.85279H21.592V4.15953L14.689 10.8221H9.31104L1.96656 17.4111L0 15.5706L8.14716 8.24544H13.485L19.7057 2.2086H17.6187V0H24Z" />
<path d="M15.3341 13.8404H10.5181V24H15.3341V13.8404Z" />
<path d="M6.6598 19.6564H1.84375V24H6.6598V19.6564Z" />


        </SvgIcon>
    )
}
        