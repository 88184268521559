import styled from "styled-components";

export const Overlay = styled("div")`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    opacity: 0.7;
    z-index: 100;
`;

export const Spinner = styled("div")`
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    top: 50%;
    position: absolute;
    left: 50%;

    animation: spin 1s ease infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
`;
