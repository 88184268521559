import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function PdfFileIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="PdfFileIcon">
            
<g id="filetype-pdf">
<path id="Union" fillRule="evenodd" clipRule="evenodd" d="M21 7.25V21.5C21 23.1569 19.6569 24.5 18 24.5H16.5V23H18C18.8284 23 19.5 22.3284 19.5 21.5V7.25H16.5C15.2574 7.25 14.25 6.24264 14.25 5V2H6C5.17157 2 4.5 2.67157 4.5 3.5V17H3V3.5C3 1.84314 4.34315 0.5 6 0.5H14.25L21 7.25ZM2.39941 18.275H0V24.2735H1.18652V22.2608H2.39062C2.82129 22.2608 3.1875 22.1744 3.48926 22.0016C3.79395 21.8258 4.02539 21.5885 4.18359 21.2897C4.34473 20.9879 4.42529 20.6495 4.42529 20.2745C4.42529 19.8995 4.34619 19.5611 4.18799 19.2594C4.02979 18.9576 3.7998 18.7189 3.49805 18.5431C3.19922 18.3644 2.83301 18.275 2.39941 18.275ZM3.2168 20.2745C3.2168 20.4972 3.17432 20.6876 3.08936 20.8458C3.00732 21.0011 2.88867 21.1212 2.7334 21.2062C2.57812 21.2882 2.39062 21.3292 2.1709 21.3292H1.18213V19.2198H2.1709C2.49902 19.2198 2.75537 19.3107 2.93994 19.4923C3.12451 19.6739 3.2168 19.9347 3.2168 20.2745ZM5.04316 18.275V24.2735H7.23164C7.83516 24.2735 8.33467 24.1549 8.73018 23.9176C9.12861 23.6803 9.42598 23.336 9.62226 22.8849C9.81855 22.4337 9.9167 21.8917 9.9167 21.2589C9.9167 20.629 9.81855 20.0914 9.62226 19.6461C9.42891 19.2008 9.13447 18.8609 8.73896 18.6266C8.34346 18.3922 7.84102 18.275 7.23164 18.275H5.04316ZM6.22969 19.2418H7.07344C7.44551 19.2418 7.7502 19.318 7.9875 19.4703C8.22773 19.6227 8.40498 19.8497 8.51924 20.1515C8.63643 20.4532 8.69502 20.8297 8.69502 21.2809C8.69502 21.6207 8.66133 21.9166 8.59394 22.1686C8.52949 22.4205 8.43135 22.6315 8.29951 22.8014C8.1706 22.9684 8.00361 23.0943 7.79853 23.1793C7.59346 23.2613 7.35176 23.3024 7.07344 23.3024H6.22969V19.2418ZM11.8441 21.8873V24.2735H10.6576V18.275H14.4809V19.255H11.8441V20.9293H14.2523V21.8873H11.8441Z" />
</g>

        </SvgIcon>
    )
}
        