import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function Graph2Icon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="Graph2Icon">
            
<path d="M6 14C6.39782 14 6.77936 13.842 7.06066 13.5607C7.34196 13.2794 7.5 12.8978 7.5 12.5C7.50488 12.4501 7.50488 12.3999 7.5 12.35L10.29 9.56H10.52H10.75L12.36 11.17C12.36 11.17 12.36 11.22 12.36 11.25C12.36 11.6478 12.518 12.0294 12.7993 12.3107C13.0806 12.592 13.4622 12.75 13.86 12.75C14.2578 12.75 14.6394 12.592 14.9207 12.3107C15.202 12.0294 15.36 11.6478 15.36 11.25V11.17L19 7.5C19.2967 7.5 19.5867 7.41203 19.8334 7.2472C20.08 7.08238 20.2723 6.84811 20.3858 6.57403C20.4993 6.29994 20.5291 5.99834 20.4712 5.70736C20.4133 5.41639 20.2704 5.14912 20.0607 4.93934C19.8509 4.72956 19.5836 4.5867 19.2926 4.52882C19.0017 4.47094 18.7001 4.50065 18.426 4.61418C18.1519 4.72771 17.9176 4.91997 17.7528 5.16664C17.588 5.41332 17.5 5.70333 17.5 6C17.4951 6.04988 17.4951 6.10012 17.5 6.15L13.89 9.76H13.73L12 8C12 7.60218 11.842 7.22064 11.5607 6.93934C11.2794 6.65804 10.8978 6.5 10.5 6.5C10.1022 6.5 9.72064 6.65804 9.43934 6.93934C9.15804 7.22064 9 7.60218 9 8L6 11C5.60218 11 5.22064 11.158 4.93934 11.4393C4.65804 11.7206 4.5 12.1022 4.5 12.5C4.5 12.8978 4.65804 13.2794 4.93934 13.5607C5.22064 13.842 5.60218 14 6 14ZM19.5 18H2.5V1C2.5 0.734784 2.39464 0.48043 2.20711 0.292893C2.01957 0.105357 1.76522 0 1.5 0C1.23478 0 0.98043 0.105357 0.792893 0.292893C0.605357 0.48043 0.5 0.734784 0.5 1V19C0.5 19.2652 0.605357 19.5196 0.792893 19.7071C0.98043 19.8946 1.23478 20 1.5 20H19.5C19.7652 20 20.0196 19.8946 20.2071 19.7071C20.3946 19.5196 20.5 19.2652 20.5 19C20.5 18.7348 20.3946 18.4804 20.2071 18.2929C20.0196 18.1054 19.7652 18 19.5 18Z" />


        </SvgIcon>
    )
}
        