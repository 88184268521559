import { lazy } from "react";
import { Route } from "react-router-dom";

export enum LandingRoutes {
    MAIN = "/",
}

const LandingPage = lazy(() => import("./LandingPage"));

export const LandingRouter = (
    <>
        <Route path={LandingRoutes.MAIN} element={<LandingPage />} />
    </>
);
