import i18n, { TranslateOptions } from "i18n-js";
import es from "./es.json";

export type KeyOfTranslations = keyof typeof es;

export const loadLocalization = (): void => {
    // Set the key-value pairs for the different languages you want to support.
    i18n.translations = {
        es: require("./es.json"),
    };
    // Set the locale once at the beginning of your app.
    i18n.locale = "es";
    // When a value is missing from a language it'll fallback to another language with the key present.
    i18n.fallbacks = true;
};

export const translate = (key: keyof typeof es, options?: TranslateOptions): string => {
    return i18n.t(key, options);
};
