import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function AddCurrencyIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="AddCurrencyIcon">
            
<path d="M19 21H5C3.89543 21 3 20.1046 3 19V15H5V19H19V5H5V9H3V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM11 16V13H3V11H11V8L16 12L11 16Z" />


        </SvgIcon>
    )
}
        