/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptDepositPaymentRequest } from '../models/AcceptDepositPaymentRequest';
import type { AcceptWithdrawPaymentRequest } from '../models/AcceptWithdrawPaymentRequest';
import type { CreateDepositPaymentRequest } from '../models/CreateDepositPaymentRequest';
import type { CreateWithdrawPaymentRequest } from '../models/CreateWithdrawPaymentRequest';
import type { DepositDto } from '../models/DepositDto';
import type { PaginatedDepositDto } from '../models/PaginatedDepositDto';
import type { PaginatedWithdrawDto } from '../models/PaginatedWithdrawDto';
import type { WithdrawDto } from '../models/WithdrawDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * Create payment deposit
     * @param requestBody
     * @returns DepositDto
     * @throws ApiError
     */
    public static paymentControllerCreateDeposit(
        requestBody: CreateDepositPaymentRequest,
    ): CancelablePromise<DepositDto> {
        return __request({
            method: 'POST',
            path: `/api/payments/deposits`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find all deposits
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterSentCurrency
     * @param filterStatus
     * @param filterUserId
     * @returns PaginatedDepositDto
     * @throws ApiError
     */
    public static paymentControllerGetAllDeposits(
        page: number,
        pageSize: number,
        filterType?: 'pesos_bank_transfer' | 'dollar_bank_transfer' | 'usdt_blockchain_transaction' | 'lyra',
        filterSentCurrency?: 'usd' | 'ars' | 'usdt',
        filterStatus?: 'pending' | 'sent' | 'completed' | 'rejected',
        filterUserId?: number,
    ): CancelablePromise<PaginatedDepositDto> {
        return __request({
            method: 'GET',
            path: `/api/payments/deposits`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[type]': filterType,
                'filter[sentCurrency]': filterSentCurrency,
                'filter[status]': filterStatus,
                'filter[userId]': filterUserId,
            },
        });
    }

    /**
     * Create payment withdraw
     * @param requestBody
     * @returns WithdrawDto
     * @throws ApiError
     */
    public static paymentControllerCreateWithdraw(
        requestBody: CreateWithdrawPaymentRequest,
    ): CancelablePromise<WithdrawDto> {
        return __request({
            method: 'POST',
            path: `/api/payments/withdraws`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find all withdraws
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterReceivedCurrency
     * @param filterStatus
     * @param filterUserId
     * @returns PaginatedWithdrawDto
     * @throws ApiError
     */
    public static paymentControllerGetAllWithdraws(
        page: number,
        pageSize: number,
        filterType?: 'pesos_bank_transfer' | 'dollar_bank_transfer' | 'usdt_blockchain_transaction' | 'stripe' | 'bitpay',
        filterReceivedCurrency?: 'usd' | 'ars' | 'eth',
        filterStatus?: 'pending' | 'sent' | 'completed' | 'rejected',
        filterUserId?: number,
    ): CancelablePromise<PaginatedWithdrawDto> {
        return __request({
            method: 'GET',
            path: `/api/payments/withdraws`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[type]': filterType,
                'filter[receivedCurrency]': filterReceivedCurrency,
                'filter[status]': filterStatus,
                'filter[userId]': filterUserId,
            },
        });
    }

    /**
     * Find all my deposits
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterSentCurrency
     * @param filterStatus
     * @returns PaginatedDepositDto
     * @throws ApiError
     */
    public static paymentControllerGetAllMyDeposits(
        page: number,
        pageSize: number,
        filterType?: 'pesos_bank_transfer' | 'dollar_bank_transfer' | 'usdt_blockchain_transaction' | 'lyra',
        filterSentCurrency?: 'usd' | 'ars' | 'usdt',
        filterStatus?: 'pending' | 'sent' | 'completed' | 'rejected',
    ): CancelablePromise<PaginatedDepositDto> {
        return __request({
            method: 'GET',
            path: `/api/payments/me/deposits`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[type]': filterType,
                'filter[sentCurrency]': filterSentCurrency,
                'filter[status]': filterStatus,
            },
        });
    }

    /**
     * Find all my withdraws
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterReceivedCurrency
     * @param filterStatus
     * @returns PaginatedWithdrawDto
     * @throws ApiError
     */
    public static paymentControllerGetAllMyWithdraws(
        page: number,
        pageSize: number,
        filterType?: 'pesos_bank_transfer' | 'dollar_bank_transfer' | 'usdt_blockchain_transaction' | 'stripe' | 'bitpay',
        filterReceivedCurrency?: 'usd' | 'ars' | 'eth',
        filterStatus?: 'pending' | 'sent' | 'completed' | 'rejected',
    ): CancelablePromise<PaginatedWithdrawDto> {
        return __request({
            method: 'GET',
            path: `/api/payments/me/withdraws`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[type]': filterType,
                'filter[receivedCurrency]': filterReceivedCurrency,
                'filter[status]': filterStatus,
            },
        });
    }

    /**
     * Accept payment deposit
     * @param id
     * @param requestBody
     * @returns DepositDto
     * @throws ApiError
     */
    public static paymentControllerAcceptDeposit(
        id: number,
        requestBody: AcceptDepositPaymentRequest,
    ): CancelablePromise<DepositDto> {
        return __request({
            method: 'PUT',
            path: `/api/payments/deposits/${id}/accept`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Reject payment deposit
     * @param id
     * @returns DepositDto
     * @throws ApiError
     */
    public static paymentControllerRejectDeposit(
        id: number,
    ): CancelablePromise<DepositDto> {
        return __request({
            method: 'POST',
            path: `/api/payments/deposits/${id}/reject`,
        });
    }

    /**
     * Send payment deposit
     * @param id
     * @returns DepositDto
     * @throws ApiError
     */
    public static paymentControllerSendDeposit(
        id: number,
    ): CancelablePromise<DepositDto> {
        return __request({
            method: 'PUT',
            path: `/api/payments/deposits/${id}/send`,
        });
    }

    /**
     * Accept payment withdraw
     * @param id
     * @param requestBody
     * @returns WithdrawDto
     * @throws ApiError
     */
    public static paymentControllerAcceptWithdraw(
        id: number,
        requestBody: AcceptWithdrawPaymentRequest,
    ): CancelablePromise<WithdrawDto> {
        return __request({
            method: 'PUT',
            path: `/api/payments/withdraws/${id}/accept`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Reject payment withdraw
     * @param id
     * @returns WithdrawDto
     * @throws ApiError
     */
    public static paymentControllerRejectWithdraw(
        id: number,
    ): CancelablePromise<WithdrawDto> {
        return __request({
            method: 'POST',
            path: `/api/payments/withdraws/${id}/reject`,
        });
    }

    /**
     * Send payment withdraw
     * @param id
     * @returns WithdrawDto
     * @throws ApiError
     */
    public static paymentControllerSendWithdraw(
        id: number,
    ): CancelablePromise<WithdrawDto> {
        return __request({
            method: 'PUT',
            path: `/api/payments/withdraws/${id}/send`,
        });
    }

    /**
     * Receive lyra ipn payment callback
     * @returns any
     * @throws ApiError
     */
    public static paymentControllerLyraIpn(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/payments/lyra-ipn`,
        });
    }

    /**
     * Get lyra exchange rate
     * @returns any
     * @throws ApiError
     */
    public static paymentControllerGetLyraRate(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/payments/lyra-rate`,
        });
    }

}