import { lazy } from "react";
import { Route } from "react-router-dom";

export enum PlatformRoutes {
    OWNER = "/owner",
    INVESTOR = "/investor",
    PROJECT_PROPERTY = "/project-property/:id",
    PROPERTY_TYPE = "/property-type/:id",
    PROPERTY_TYPE_IMAGES = "/property-type/:id/images",
    PROPERTIES = "/properties",
    PROJECT_INVESTOR = "/project-investor/:id",
    CONTRACTS = "/contracts",
}

const ProjectProperty = lazy(() => import("./ProjectProperty"));
const PropertyType = lazy(() => import("./PropertyType"));
const PropertyTypeImages = lazy(() => import("./PropertyTypeImages"));
const Projects = lazy(() => import("./Projects"));
const Properties = lazy(() => import("./Properties"));
const Contracts = lazy(() => import("./Contracts"));

export const PlatformRouter = (
    <>
        <Route path={PlatformRoutes.OWNER} element={<Projects type={"property"} />} />
        <Route path={PlatformRoutes.INVESTOR} element={<Projects type={"investment"} />} />
        <Route path={PlatformRoutes.PROJECT_PROPERTY} element={<ProjectProperty type={"property"} />} />
        <Route path={PlatformRoutes.PROPERTY_TYPE_IMAGES} element={<PropertyTypeImages />} />
        <Route path={PlatformRoutes.PROPERTY_TYPE} element={<PropertyType />} />
        <Route path={PlatformRoutes.PROPERTIES} element={<Properties type={"property"} />} />
        <Route path={PlatformRoutes.PROJECT_INVESTOR} element={<ProjectProperty type={"investment"} />} />
        <Route path={PlatformRoutes.CONTRACTS} element={<Contracts />} />
    </>
);
