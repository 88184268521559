import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function PesosIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="PesosIcon">
            
<path d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 22.4C6.264 22.4 1.6 17.736 1.6 12C1.6 6.264 6.264 1.6 12 1.6C17.736 1.6 22.4 6.264 22.4 12C22.4 17.736 17.736 22.4 12 22.4Z" />
<path d="M16 9.6V8.8C16 7.544 15.312 5.264 12.8 4.872V3.2H11.2V4.88C9.792 5.152 8 6.176 8 8.8C8 10.72 9.616 11.688 11.2 12.488V17.4712C10.488 17.2632 9.6 16.7032 9.6 15.1992V14.3992H8V15.1992C8 17.8232 9.792 18.8472 11.2 19.1192V20.8H12.8V19.12C14.208 18.8472 16 17.824 16 15.2C16 13.28 14.384 12.312 12.8 11.512V6.504C14.28 6.912 14.392 8.44 14.4 8.8V9.6H16ZM11.2 10.68C10.184 10.104 9.6 9.576 9.6 8.8C9.6 7.296 10.488 6.736 11.2 6.528V10.68ZM14.4 15.2C14.4 16.704 13.512 17.264 12.8 17.472V13.32C13.816 13.896 14.4 14.424 14.4 15.2Z" />


        </SvgIcon>
    )
}
        