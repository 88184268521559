import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function DollarIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="DollarIcon">
            
<path d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 22.4C6.264 22.4 1.6 17.736 1.6 12C1.6 6.264 6.264 1.6 12 1.6C17.736 1.6 22.4 6.264 22.4 12C22.4 17.736 17.736 22.4 12 22.4Z" />
<path d="M14.4 9.6V8.8C14.392 8.384 14.248 6.4 12 6.4C11.6 6.408 9.6 6.544 9.6 8.8C9.6 9.832 10.64 10.424 12.36 11.288C14.064 12.136 16 13.104 16 15.2C16 16.856 15.28 17.88 14.4 18.464V20.8H12.8V19.12C12.512 19.1752 12.232 19.2 12 19.2C11.768 19.2 11.488 19.176 11.2 19.12V20.8H9.6V18.464C8.72 17.88 8 16.856 8 15.2V14.4H9.6V15.2C9.6 17.456 11.6 17.592 12.008 17.6C12.4 17.592 14.4 17.456 14.4 15.2C14.4 14.168 13.36 13.576 11.64 12.712C9.936 11.864 8 10.896 8 8.8C8 7.144 8.72 6.12 9.6 5.536V3.2H11.2V4.88C11.488 4.824 11.768 4.8 12 4.8C12.288 4.8 12.552 4.824 12.8 4.872V3.2H14.4V5.504C15.624 6.368 16 7.864 16 8.8V9.6H14.4Z" />


        </SvgIcon>
    )
}
        