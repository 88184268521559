import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ValidIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ValidIcon">
            
<path d="M24 5.2039L21.5309 3L12.1746 13.4824L10.9978 16.0874L12.1746 18.4526L24 5.2039Z" />
<path d="M2.34015 8.8877L0 11.228L9.83908 21.0671L12.1729 18.4524V13.4822L9.7025 16.2499L2.34015 8.8877Z" />


        </SvgIcon>
    )
}
        