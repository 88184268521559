import { lazy } from "react";
import { Route } from "react-router-dom";

export enum DashboardRoutes {
    HOME = "/",
    ADD = "/new-project",
    DASHBOARD = "/dashboard",
    PROPERTY = "/property/:id",
    PROJECT = "/project/:id",
    EDIT = "/edit-project/:id",
}

const DashboardPage = lazy(() => import("./DashboardPage"));
const CreateProjectPage = lazy(() => import("./CreateProject"));
const HomePage = lazy(() => import("./HomePage"));
const CreatePropertyPage = lazy(() => import("./CreatePropertyPage"));
const ProjectPage = lazy(() => import("./ProjectPage"));
const EditProjectPage = lazy(() => import("./EditProject"));

export const DashboardRouter = (
    <>
        <Route path={DashboardRoutes.HOME} element={<HomePage />} />
        <Route path={DashboardRoutes.DASHBOARD} element={<DashboardPage />} />
        <Route path={DashboardRoutes.ADD} element={<CreateProjectPage />} />
        <Route path={DashboardRoutes.PROPERTY} element={<CreatePropertyPage />} />
        <Route path={DashboardRoutes.PROJECT} element={<ProjectPage />} />
        <Route path={DashboardRoutes.EDIT} element={<EditProjectPage />} />
    </>
);
