import { lazy } from "react";
import { Route } from "react-router-dom";

export enum AuthRoutes {
    RESET_PASSWORD = "/reset-password",
    VERIFY_EMAIL = "/verify-email",
    LOGIN = "/login",
    REGISTER = "/sign-up",
}

const ResetPasswordPage = lazy(() => import("./ResetPasswordPage"));
const VerifyAccountPage = lazy(() => import("./VerifyAccountPage"));
const LoginPage = lazy(() => import("./LoginPage"));
const RegisterPage = lazy(() => import("./RegisterPage"));

export const AuthRouter = (
    <>
        <Route path={AuthRoutes.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={AuthRoutes.VERIFY_EMAIL} element={<VerifyAccountPage />} />
        <Route path={AuthRoutes.LOGIN} element={<LoginPage />} />
        <Route path={AuthRoutes.REGISTER} element={<RegisterPage />} />
    </>
);
