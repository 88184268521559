import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function DeleteIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="DeleteIcon">
            
<path d="M3.27562 0.914311L10.4043 8.29623L17.8148 1.13994L20.2293 3.64026L12.8188 10.7966L19.9474 18.1785L17.5451 20.4984L10.4165 13.1164L2.96519 20.3121L0.550654 17.8117L8.00193 10.6161L0.873298 3.23421L3.27562 0.914311Z" />


        </SvgIcon>
    )
}
        