import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function BucketIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="BucketIcon">
            
<path d="M8.25 18.25C8.66421 18.25 9 18.5858 9 19V28C9 28.4142 8.66421 28.75 8.25 28.75C7.83579 28.75 7.5 28.4142 7.5 28V19C7.5 18.5858 7.83579 18.25 8.25 18.25Z" />
<path d="M12 18.25C12.4142 18.25 12.75 18.5858 12.75 19V28C12.75 28.4142 12.4142 28.75 12 28.75C11.5858 28.75 11.25 28.4142 11.25 28V19C11.25 18.5858 11.5858 18.25 12 18.25Z" />
<path d="M16.5 19C16.5 18.5858 16.1642 18.25 15.75 18.25C15.3358 18.25 15 18.5858 15 19V28C15 28.4142 15.3358 28.75 15.75 28.75C16.1642 28.75 16.5 28.4142 16.5 28V19Z" />
<path fillRule="evenodd" clipRule="evenodd" d="M21.75 14.5C21.75 15.3284 21.0784 16 20.25 16H19.5V29.5C19.5 31.1569 18.1569 32.5 16.5 32.5H7.5C5.84315 32.5 4.5 31.1569 4.5 29.5V16H3.75C2.92157 16 2.25 15.3284 2.25 14.5V13C2.25 12.1716 2.92157 11.5 3.75 11.5H9C9 10.6716 9.67157 10 10.5 10H13.5C14.3284 10 15 10.6716 15 11.5H20.25C21.0784 11.5 21.75 12.1716 21.75 13V14.5ZM6.17705 16L6 16.0885V29.5C6 30.3284 6.67157 31 7.5 31H16.5C17.3284 31 18 30.3284 18 29.5V16.0885L17.8229 16H6.17705ZM3.75 14.5V13H20.25V14.5H3.75Z" />

        </SvgIcon>
    )
}
        