import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function HelpIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="HelpIcon">
            
<path d="M12 23.4783C18.6274 23.4783 24 18.3393 24 12C24 5.66073 18.6274 0.52174 12 0.52174C5.37258 0.52174 0 5.66073 0 12C0 18.3393 5.37258 23.4783 12 23.4783Z" />
<path d="M10.1666 13.3155C10.1666 12.6498 10.2511 12.1196 10.4201 11.7248C10.5891 11.3302 10.8974 10.9426 11.3448 10.5622C11.7972 10.177 12.098 9.86549 12.2472 9.62771C12.3963 9.38519 12.4708 9.13078 12.4708 8.86447C12.4708 8.0608 12.0831 7.65896 11.3075 7.65896C10.9396 7.65896 10.6438 7.76834 10.4201 7.9871C10.2014 8.20109 10.087 8.49831 10.0771 8.87874H7.91441C7.92436 7.97045 8.23011 7.25951 8.83167 6.74592C9.43821 6.23234 10.2635 5.97554 11.3075 5.97554C12.3615 5.97554 13.1794 6.22044 13.7611 6.71025C14.3427 7.19531 14.6336 7.88248 14.6336 8.77174C14.6336 9.17595 14.5391 9.55876 14.3501 9.92018C14.1612 10.2768 13.8307 10.6739 13.3583 11.1114L12.7543 11.6607C12.3765 12.0078 12.1601 12.4144 12.1055 12.8805L12.0756 13.3155H10.1666ZM9.95028 15.5055C9.95028 15.1868 10.0621 14.9253 10.2859 14.7208C10.5146 14.5115 10.8054 14.4069 11.1584 14.4069C11.5114 14.4069 11.7997 14.5115 12.0235 14.7208C12.2521 14.9253 12.3664 15.1868 12.3664 15.5055C12.3664 15.8193 12.2546 16.0784 12.0309 16.283C11.8121 16.4875 11.5213 16.5896 11.1584 16.5896C10.7955 16.5896 10.5021 16.4875 10.2784 16.283C10.0597 16.0784 9.95028 15.8193 9.95028 15.5055Z" />


        </SvgIcon>
    )
}
        