/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DepositPropertyTokenRequest } from '../models/DepositPropertyTokenRequest';
import type { InvestInvestmentTokenRequest } from '../models/InvestInvestmentTokenRequest';
import type { PaginatedUserTokenDto } from '../models/PaginatedUserTokenDto';
import type { PaginatedUserTokenPurchaseDto } from '../models/PaginatedUserTokenPurchaseDto';
import type { PurchaseInvestmentTokenRequest } from '../models/PurchaseInvestmentTokenRequest';
import type { PurchasePropertyTokenRequest } from '../models/PurchasePropertyTokenRequest';
import type { ReservePropertyRequest } from '../models/ReservePropertyRequest';
import type { UserTokenDto } from '../models/UserTokenDto';
import type { UserTokenInContractDto } from '../models/UserTokenInContractDto';
import type { UserTokenInPropertyDto } from '../models/UserTokenInPropertyDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { PrivateUserPropertyDepositDto, UserPropertyDepositDto } from '../models/UserPropertyDepositDto';
import { PrivateUserContractDepositDto, UserContractDepositDto } from '../models/UserContractDepositDto';
import { UserContractCollectedDto } from '../models/UserContractCollectedDto';
import { UserRentBalanceDto } from '../models/UserRentBalance.dto';
import { UserRentCollectDto } from '../models/UserRentCollect.dto';
import { RefundPropertyTokensRequest } from '../models/RefundPropertyTokensRequest';

export class TokenService {

    /**
     * Find all user tokens
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterProjectId
     * @param filterAvailableAmount
     * @param filterAvailableAmountLt
     * @param filterAvailableAmountGt
     * @param filterUserId
     * @returns PaginatedUserTokenDto
     * @throws ApiError
     */
    public static tokenControllerGetAllUserTokens(
        page: number,
        pageSize: number,
        filterType?: 'property' | 'investment',
        filterProjectId?: number,
        filterAvailableAmount?: number,
        filterAvailableAmountLt?: number,
        filterAvailableAmountGt?: number,
        filterUserId?: number,
    ): CancelablePromise<PaginatedUserTokenDto> {
        return __request({
            method: 'GET',
            path: `/api/tokens`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[type]': filterType,
                'filter[projectId]': filterProjectId,
                'filter[availableAmount]': filterAvailableAmount,
                'filter[availableAmountLT]': filterAvailableAmountLt,
                'filter[availableAmountGT]': filterAvailableAmountGt,
                'filter[userId]': filterUserId,
            },
        });
    }

    /**
     * Find my user tokens
     * @param page
     * @param pageSize
     * @param filterType
     * @param filterProjectId
     * @param filterAvailableAmount
     * @param filterAvailableAmountLt
     * @param filterAvailableAmountGt
     * @returns PaginatedUserTokenDto
     * @throws ApiError
     */
    public static tokenControllerGetMyUserTokens(
        page: number,
        pageSize: number,
        filterType?: 'property' | 'investment',
        filterProjectId?: number,
        filterAvailableAmount?: number,
        filterAvailableAmountLt?: number,
        filterAvailableAmountGt?: number,
    ): CancelablePromise<PaginatedUserTokenDto> {
        return __request({
            method: 'GET',
            path: `/api/tokens/me`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[type]': filterType,
                'filter[projectId]': filterProjectId,
                'filter[availableAmount]': filterAvailableAmount,
                'filter[availableAmountLT]': filterAvailableAmountLt,
                'filter[availableAmountGT]': filterAvailableAmountGt,
            },
        });
    }

    /**
     * Find all user tokens purchases
     * @param page
     * @param pageSize
     * @returns PaginatedUserTokenPurchaseDto
     * @throws ApiError
     */
    public static tokenControllerGetUserTokenPurchases(
        page: number,
        pageSize: number,
    ): CancelablePromise<PaginatedUserTokenPurchaseDto> {
        return __request({
            method: 'GET',
            path: `/api/tokens/purchases`,
            query: {
                'page': page,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Find my tokens purchases
     * @param page
     * @param pageSize
     * @returns PaginatedUserTokenPurchaseDto
     * @throws ApiError
     */
    public static tokenControllerGetMyUserTokenPurchases(
        page: number,
        pageSize: number,
    ): CancelablePromise<PaginatedUserTokenPurchaseDto> {
        return __request({
            method: 'GET',
            path: `/api/tokens/purchases/me`,
            query: {
                'page': page,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Buy property tokens of a project
     * @param requestBody
     * @returns UserTokenDto
     * @throws ApiError
     */
    public static tokenControllerPurchasePropertyTokens(
        requestBody: PurchasePropertyTokenRequest,
    ): CancelablePromise<UserTokenDto> {
        return __request({
            method: 'POST',
            path: `/api/tokens/property`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Make a reservation of a property with id :id
     * @param requestBody
     * @returns UserTokenInPropertyDto
     * @throws ApiError
     */
    public static tokenControllerReserveProperty(
        requestBody: ReservePropertyRequest,
    ): CancelablePromise<UserTokenInPropertyDto> {
        return __request({
            method: 'POST',
            path: `/api/tokens/property/reservation`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    public static tokenControllerRefundPropertyTokens(
        requestBody: RefundPropertyTokensRequest,
    ): CancelablePromise<UserTokenInPropertyDto> {
        return __request({
            method: 'POST',
            path: `/api/tokens/property/refund`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deposit property tokens to your reservation of a property with id :id
     * @param requestBody
     * @returns UserTokenInPropertyDto
     * @throws ApiError
     */
    public static tokenControllerDepositTokensInReservation(
        requestBody: DepositPropertyTokenRequest,
    ): CancelablePromise<UserTokenInPropertyDto> {
        return __request({
            method: 'POST',
            path: `/api/tokens/property/deposit`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Buy investment tokens of a project
     * @param requestBody
     * @returns UserTokenDto
     * @throws ApiError
     */
    public static tokenControllerPurchaseInvestmentTokens(
        requestBody: PurchaseInvestmentTokenRequest,
    ): CancelablePromise<UserTokenDto> {
        return __request({
            method: 'POST',
            path: `/api/tokens/investment`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Invest investment tokens in a contract a.k.a. vault
     * @param requestBody
     * @returns UserTokenInContractDto
     * @throws ApiError
     */
    public static tokenControllerInvestTokensInContract(
        requestBody: InvestInvestmentTokenRequest,
    ): CancelablePromise<UserTokenInContractDto> {
        return __request({
            method: 'POST',
            path: `/api/tokens/investment/invest`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find all my property deposits
     * @returns UserPropertyDepositDto
     * @throws ApiError
     */
    public static tokenControllerGetMyPropertyDeposits(): CancelablePromise<UserPropertyDepositDto[]> {
        return __request({
            method: 'GET',
            path: `/api/tokens/property/deposits/me`,
        });
    }

    /**
     * Find all my contract deposits
     * @returns UserPropertyDepositDto
     * @throws ApiError
     */
    public static tokenControllerGetMyContractDeposits(): CancelablePromise<UserContractDepositDto[]> {
        return __request({
            method: 'GET',
            path: `/api/tokens/investment/deposits/me`,
        });
    }

    /**
     * Find all property deposits of specific project
     * @returns PrivateUserPropertyDepositDto
     * @throws ApiError
     */
    public static tokenControllerGetAllPropertyDepositsByProjectId(projectId: number): CancelablePromise<PrivateUserPropertyDepositDto[]> {
        return __request({
            method: 'GET',
            path: `/api/tokens/property/deposits/${projectId}`
        });
    }

    /**
     * Find all contract deposits of specific project
     * @returns PrivateUserContractDepositDto
     * @throws ApiError
     */
    public static tokenControllerGetAllContractDepositsByProjectId(projectId: number): CancelablePromise<PrivateUserContractDepositDto[]> {
        return __request({
            method: 'GET',
            path: `/api/tokens/investment/deposits/${projectId}`
        })
    }

    /**
     * Find all my contract rewards
     * @returns UserPropertyDepositDto
     * @throws ApiError
     */
    public static tokenControllerGetMyContractRewards(): CancelablePromise<UserContractCollectedDto[]> {
        return __request({
            method: 'GET',
            path: `/api/tokens/investment/rewards/me`,
        });
    }

    public static tokenControllerGetMyRentBalances(): CancelablePromise<UserRentBalanceDto[]> {
        return __request({
            method: "GET",
            path: "/api/tokens/rent/balance"
        });
    }

    public static tokenControllerCollectRent(propertyId: number): CancelablePromise<UserRentCollectDto> {
        return __request({
            method: "POST",
            path: `/api/tokens/rent/balance/${propertyId}`
        });
    }
}