import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function CloseIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="CloseIcon">
            
<path d="M0.96967 0.46967C1.26256 0.176777 1.73744 0.176777 2.03033 0.46967L6 4.43934L9.96967 0.46967C10.2626 0.176777 10.7374 0.176777 11.0303 0.46967C11.3232 0.762563 11.3232 1.23744 11.0303 1.53033L7.06066 5.5L11.0303 9.46967C11.3232 9.76256 11.3232 10.2374 11.0303 10.5303C10.7374 10.8232 10.2626 10.8232 9.96967 10.5303L6 6.56066L2.03033 10.5303C1.73744 10.8232 1.26256 10.8232 0.96967 10.5303C0.676777 10.2374 0.676777 9.76256 0.96967 9.46967L4.93934 5.5L0.96967 1.53033C0.676777 1.23744 0.676777 0.762563 0.96967 0.46967Z" />

        </SvgIcon>
    )
}
        