import { ValidIcon } from "icons";
import { createTheme } from "@peersyst/react-components";
import { translate } from "locale";

export const theme = createTheme({
    icons: {
        success: ValidIcon,
    },
    palette: {
        primary: "#27AE60",
        primaryDark: "#247C49",
        white: "#FFFFFF",
        black: "#000000",
        lightBack: "#020202",
        owner: "#FA832E",
        investor: "#156AD3",
        darkBlue: "#03396A",
        success: "#27AE60",
        text: "#333232",
        error: "#ae2727",
        yellow: "#FBC400",
        errorDarker: "#850707",
        background: "#f7f7f7",
        warning: "#ffefcf",
        whatsapp: "#25D366",
        facebook: "#3B5998",
        linkedin: "#057ebb",
        grey: "#D2D2D2",
        lightGrey: "#F6F6F6",
        mediumGrey: "#A6A6A6",
        darkGrey: "#6B6B6B",
        placeHolder: "#032d23",
        status: ["#A8FF9A", "#F79A85", "#D2D2D2", "#868686", "#FBC400"],
    },
    gradient: {
        blackToTransparent: "linear-gradient(93.8deg, #1d1c1c 45%, rgba(29, 28, 28, 0) 80%)",
        colorfulGradient: "linear-gradient(95.92deg, #A5D4FF 7.81%, #E19118 38.51%, #FC856A 64.99%, #E467B2 80.73%)",
        telegramGradient: "linear-gradient(203.2deg, #37AEE2 21.67%, #1E96C8 70%)",
        instagramGradient: "linear-gradient(60.44deg, #F8C02D 14.09%, #F92023 83.93%)",
    },
    typography: {
        h1: {
            style: {
                fontSize: "3rem",
                fontWeight: 700,
            },
        },
        h2: {
            style: {
                fontSize: "2rem",
                fontWeight: 700,
            },
        },
        h3: {
            style: {
                fontSize: "1.5rem",
                fontWeight: 600,
            },
        },
        button: {
            style: {
                fontWeight: 600,
                textTransform: "capitalize",
            },
        },
    },
    zIndex: {
        header: 80,
    },
    translate,
    blockchainLinks: {
        address: process.env.REACT_APP_EXPLORER_LINK + "address/",
        tx: process.env.REACT_APP_EXPLORER_LINK + "tx/",
    },
});
