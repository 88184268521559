import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ArrowDownIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ArrowDownIcon">
            
<path d="M12.3906 24L0.390628 -2.8134e-06L24.3906 -7.15256e-07L12.3906 24Z" />


        </SvgIcon>
    )
}
        