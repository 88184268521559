import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ArrowIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ArrowIcon">
            
<path d="M3.34407e-07 11.9765C3.42595e-07 12.4638 0.163049 12.9311 0.45328 13.2756C0.743511 13.6201 1.13715 13.8137 1.5476 13.8137L18.5209 13.8137L12.2299 20.7766C12.073 20.9385 11.9452 21.1364 11.8542 21.3583C11.7631 21.5802 11.7107 21.8216 11.6999 22.0681C11.6892 22.3146 11.7204 22.5611 11.7918 22.7929C11.8631 23.0248 11.973 23.2372 12.115 23.4175C12.257 23.5978 12.4281 23.7423 12.6182 23.8423C12.8083 23.9424 13.0134 23.9959 13.2213 23.9998C13.4292 24.0036 13.6356 23.9577 13.8282 23.8648C14.0208 23.7718 14.1957 23.6338 14.3424 23.4588L23.508 13.3223C23.6633 13.1504 23.7871 12.9424 23.8717 12.7113C23.9563 12.4802 24 12.2308 24 11.9788C24 11.7268 23.9563 11.4775 23.8717 11.2464C23.7871 11.0153 23.6633 10.8073 23.508 10.6354L14.3424 0.494231C14.0422 0.161684 13.6431 -0.0157034 13.2328 0.00109266C12.8225 0.0178887 12.4346 0.227493 12.1545 0.583793C11.8743 0.940093 11.7249 1.4139 11.739 1.90099C11.7532 2.38808 11.9298 2.84854 12.2299 3.18109L18.5209 10.1394L1.5476 10.1394C1.13715 10.1394 0.743511 10.3329 0.45328 10.6775C0.163049 11.022 3.2622e-07 11.4893 3.34407e-07 11.9765Z" />


        </SvgIcon>
    )
}
        