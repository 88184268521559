/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectRequest } from '../models/CreateProjectRequest';
import type { PaginatedProjectContractDto } from '../models/PaginatedProjectContractDto';
import type { PaginatedProjectDto } from '../models/PaginatedProjectDto';
import type { PaginatedProjectPropertyDto } from '../models/PaginatedProjectPropertyDto';
import type { ProjectDto } from '../models/ProjectDto';
import type { ProjectTaskDto } from '../models/ProjectTaskDto';
import type { ProjectTaskResourceDto } from '../models/ProjectTaskResourceDto';
import type { UpdateProjectRequest } from '../models/UpdateProjectRequest';
import type { UpdateProjectTaskRequest } from '../models/UpdateProjectTaskRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { ProjectContractDto } from '../models/ProjectContractDto';
import { ProjectPropertyDto } from '../models/ProjectPropertyDto';
import { ProjectPropertyTypeDto } from '../models/ProjectPropertyTypeDto';

export class ProjectService {

    /**
     * Create project and publish it
     * @param requestBody
     * @returns ProjectDto
     * @throws ApiError
     */
    public static projectControllerCreate(
        requestBody: CreateProjectRequest,
    ): CancelablePromise<ProjectDto> {
        return __request({
            method: 'POST',
            path: `/api/projects`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find all projects
     * @param page
     * @param pageSize
     * @param filterName
     * @param filterStatus
     * @returns PaginatedProjectDto
     * @throws ApiError
     */
    public static projectControllerGetAll(
        page: number,
        pageSize: number,
        filterName?: string,
        filterStatus?: 'draft' | 'published' | 'paused' | 'cancelled' | 'finished',
    ): CancelablePromise<PaginatedProjectDto> {
        return __request({
            method: 'GET',
            path: `/api/projects`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[name]': filterName,
                'filter[status]': filterStatus,
            },
        });
    }
    public static projectControllerGetAllGuest(
        page: number,
        pageSize: number,
        filterName?: string,
        filterStatus?: 'draft' | 'published' | 'paused' | 'cancelled' | 'finished',
    ): CancelablePromise<PaginatedProjectDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/guest`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[name]': filterName,
                'filter[status]': filterStatus,
            },
        });
    }
    /**
     * Find contracts filtered
     * @param page
     * @param pageSize
     * @returns PaginatedProjectContractDto
     * @throws ApiError
     */
    public static projectControllerFindContracts(
        page: number,
        pageSize: number,
    ): CancelablePromise<PaginatedProjectContractDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/contracts`,
            query: {
                'page': page,
                'pageSize': pageSize,
            },
        });
    }
    public static projectControllerFindContractsGuest(
        page: number,
        pageSize: number,
    ): CancelablePromise<PaginatedProjectContractDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/contracts/guest`,
            query: {
                'page': page,
                'pageSize': pageSize,
            },
        });
    }
    /**
     * Find user contracts filtered
     * @param page
     * @param pageSize
     * @param filterStatus
     * @returns PaginatedProjectContractDto
     * @throws ApiError
     */
    public static projectControllerFindUserContracts(
        page: number,
        pageSize: number,
        filterStatus?: 'pending' | 'completed',
    ): CancelablePromise<PaginatedProjectContractDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/contracts/me`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[status]': filterStatus,
            },
        });
    }

    /**
     * Find user properties filtered
     * @param page
     * @param pageSize
     * @param filterStatus
     * @returns PaginatedProjectPropertyDto
     * @throws ApiError
     */
    public static projectControllerFindUserProperties(
        page: number,
        pageSize: number,
        filterStatus?: 'pending' | 'completed',
    ): CancelablePromise<PaginatedProjectPropertyDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/properties/me`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'filter[status]': filterStatus,
            },
        });
    }

    /**
     * Find user properties filtered
     * @param page
     * @param pageSize
     * @param filterStatus
     * @returns PaginatedProjectPropertyDto
     * @throws ApiError
     */
        public static projectControllerFindProperties(
            page: number,
            pageSize: number,
            filterStatus?: 'pending' | 'completed',
        ): CancelablePromise<PaginatedProjectPropertyDto> {
            return __request({
                method: 'GET',
                path: `/api/projects/properties`,
                query: {
                    'page': page,
                    'pageSize': pageSize,
                    'filter[status]': filterStatus,
                },
            });
        }

    /**
     * Show propertyType info
     * @param id
     * @returns ProjectPropertyTypeDto
     * @throws ApiError
     */
    public static projectControllerGetPropertyType(
        id: number,
    ): CancelablePromise<ProjectPropertyTypeDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/property-types/${id}`,
        });
    }
    public static projectControllerGetPropertyTypeGuest(
        id: number,
    ): CancelablePromise<ProjectPropertyTypeDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/property-types/${id}/guest`,
        });
    }
    /**
     * Upload file and add a resource to project task
     * @param taskId
     * @param formData
     * @returns ProjectTaskResourceDto
     * @throws ApiError
     */
    public static projectControllerUploadProjectTaskResource(
        taskId: number,
        formData: {
            file?: Blob;
        },
    ): CancelablePromise<ProjectTaskResourceDto> {
        return __request({
            method: 'POST',
            path: `/api/projects/tasks/${taskId}/resources`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Update project task percentages info
     * @param taskId
     * @param requestBody
     * @returns ProjectTaskDto
     * @throws ApiError
     */
    public static projectControllerSetProjectTaskPercentages(
        taskId: number,
        requestBody: UpdateProjectTaskRequest,
    ): CancelablePromise<ProjectTaskDto> {
        return __request({
            method: 'PUT',
            path: `/api/projects/tasks/${taskId}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Show project info
     * @param id
     * @returns ProjectDto
     * @throws ApiError
     */
    public static projectControllerGetProject(
        id: number,
    ): CancelablePromise<ProjectDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/${id}`,
        });
    }
    public static projectControllerGetProjectGuest(
        id: number,
    ): CancelablePromise<ProjectDto> {
        return __request({
            method: 'GET',
            path: `/api/projects/${id}/guest`,
        });
    }
    /**
     * Update project info
     * @param id
     * @param requestBody
     * @returns ProjectDto
     * @throws ApiError
     */
    public static projectControllerUpdateProject(
        id: number,
        requestBody: UpdateProjectRequest,
    ): CancelablePromise<ProjectDto> {
        return __request({
            method: 'PUT',
            path: `/api/projects/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Publish project
     * @param id
     * @returns ProjectDto
     * @throws ApiError
     */
    public static projectControllerPublish(
        id: number,
    ): CancelablePromise<ProjectDto> {
        return __request({
            method: 'POST',
            path: `/api/projects/${id}/publish`,
        });
    }

    /**
     * Publish project
     * @param id
     * @returns ProjectContractDto[]
     * @throws ApiError
     */
    public static projectControllerProjectContracts(id: number): CancelablePromise<ProjectContractDto[]> {
        return __request({
            method: "GET",
            path:  `/api/projects/contracts/${id}`
        });
    }

    /**
     * Get project properties
     * @param id
     * @returns ProjectPropertyDto[]
     * @throws ApiError
     */
    public static projectControllerProjectProperties(id: number): CancelablePromise<ProjectPropertyDto[]> {
        return __request({
            method: "GET",
            path: `/api/projects/properties/${id}`
        });
    }

}