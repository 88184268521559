import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function HouseOutlinedIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="HouseOutlinedIcon">
            
  <path d="M6 3.75C6 3.33579 6.33579 3 6.75 3H8.25C8.66421 3 9 3.33579 9 3.75V5.25C9 5.66421 8.66421 6 8.25 6H6.75C6.33579 6 6 5.66421 6 5.25V3.75Z" />
  <path d="M10.5 3.75C10.5 3.33579 10.8358 3 11.25 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75V5.25C13.5 5.66421 13.1642 6 12.75 6H11.25C10.8358 6 10.5 5.66421 10.5 5.25V3.75Z" />
  <path d="M15.75 3C15.3358 3 15 3.33579 15 3.75V5.25C15 5.66421 15.3358 6 15.75 6H17.25C17.6642 6 18 5.66421 18 5.25V3.75C18 3.33579 17.6642 3 17.25 3H15.75Z" />
  <path d="M6 8.25C6 7.83579 6.33579 7.5 6.75 7.5H8.25C8.66421 7.5 9 7.83579 9 8.25V9.75C9 10.1642 8.66421 10.5 8.25 10.5H6.75C6.33579 10.5 6 10.1642 6 9.75V8.25Z" />
  <path d="M11.25 7.5C10.8358 7.5 10.5 7.83579 10.5 8.25V9.75C10.5 10.1642 10.8358 10.5 11.25 10.5H12.75C13.1642 10.5 13.5 10.1642 13.5 9.75V8.25C13.5 7.83579 13.1642 7.5 12.75 7.5H11.25Z" />
  <path d="M15 8.25C15 7.83579 15.3358 7.5 15.75 7.5H17.25C17.6642 7.5 18 7.83579 18 8.25V9.75C18 10.1642 17.6642 10.5 17.25 10.5H15.75C15.3358 10.5 15 10.1642 15 9.75V8.25Z" />
  <path d="M6.75 12C6.33579 12 6 12.3358 6 12.75V14.25C6 14.6642 6.33579 15 6.75 15H8.25C8.66421 15 9 14.6642 9 14.25V12.75C9 12.3358 8.66421 12 8.25 12H6.75Z" />
  <path d="M10.5 12.75C10.5 12.3358 10.8358 12 11.25 12H12.75C13.1642 12 13.5 12.3358 13.5 12.75V14.25C13.5 14.6642 13.1642 15 12.75 15H11.25C10.8358 15 10.5 14.6642 10.5 14.25V12.75Z" />
  <path d="M15.75 12C15.3358 12 15 12.3358 15 12.75V14.25C15 14.6642 15.3358 15 15.75 15H17.25C17.6642 15 18 14.6642 18 14.25V12.75C18 12.3358 17.6642 12 17.25 12H15.75Z" />
  <path d="M3 1.5C3 0.671574 3.67157 0 4.5 0H19.5C20.3284 0 21 0.671573 21 1.5V22.5C21 23.3284 20.3284 24 19.5 24H4.5C3.67157 24 3 23.3284 3 22.5V1.5ZM19.5 1.5L4.5 1.5V22.5H9V18.75C9 18.3358 9.33579 18 9.75 18H14.25C14.6642 18 15 18.3358 15 18.75V22.5H19.5V1.5Z" />

        </SvgIcon>
    )
}
        