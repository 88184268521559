import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ArrowUpIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ArrowUpIcon">
            
<path d="M12 0L24 24H0L12 0Z" />


        </SvgIcon>
    )
}
        