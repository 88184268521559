/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { PaginatedPrivateUserDto } from '../models/PaginatedPrivateUserDto';
import type { PrivateUserDto } from '../models/PrivateUserDto';
import type { RegisterUserRequest } from '../models/RegisterUserRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserDto } from '../models/UserDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { CheckPasswordDto } from '../models/CheckPasswordDto';
import { CheckMyPasswordRequest } from '../models/CheckMyPasswordRequest';

export class UserService {

    /**
     * Create user
     * @param requestBody
     * @returns UserDto
     * @throws ApiError
     */
    public static userControllerCreate(
        requestBody: CreateUserRequest,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'POST',
            path: `/api/users`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Find all users
     * @param page
     * @param pageSize
     * @param type
     * @returns PaginatedPrivateUserDto
     * @throws ApiError
     */
    public static userControllerGetAll(
        page: number,
        pageSize: number,
        type?: string,
    ): CancelablePromise<PaginatedPrivateUserDto> {
        return __request({
            method: 'GET',
            path: `/api/users`,
            query: {
                'page': page,
                'pageSize': pageSize,
                'type': type,
            },
        });
    }

    /**
     * Register user
     * @param requestBody
     * @returns UserDto
     * @throws ApiError
     */
    public static userControllerRegister(
        requestBody: RegisterUserRequest,
    ): CancelablePromise<UserDto> {
        return __request({
            method: 'POST',
            path: `/api/users/register`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Show current authenticated user info
     * @returns PrivateUserDto
     * @throws ApiError
     */
    public static userControllerGetMe(): CancelablePromise<PrivateUserDto> {
        return __request({
            method: 'GET',
            path: `/api/users/me`,
        });
    }

    /**
     * Update current authenticated user info
     * @param requestBody
     * @returns PrivateUserDto
     * @throws ApiError
     */
    public static userControllerUpdateMe(
        requestBody: UpdateUserRequest,
    ): CancelablePromise<PrivateUserDto> {
        return __request({
            method: 'PUT',
            path: `/api/users/me`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change current authenticated user password
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static userControllerChangeMePassword(
        requestBody: ChangePasswordRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/users/me/change-password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Show user info
     * @param id
     * @returns PrivateUserDto
     * @throws ApiError
     */
    public static userControllerGetUser(
        id: number,
    ): CancelablePromise<PrivateUserDto> {
        return __request({
            method: 'GET',
            path: `/api/users/${id}`,
        });
    }

    /**
     * Update user info
     * @param id
     * @param requestBody
     * @returns PrivateUserDto
     * @throws ApiError
     */
    public static userControllerUpdateUser(
        id: number,
        requestBody: UpdateUserRequest,
    ): CancelablePromise<PrivateUserDto> {
        return __request({
            method: 'PUT',
            path: `/api/users/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * (Soft) delete user
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerDeleteUser(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/users/${id}`,
        });
    }

    /**
     * (Soft) delete current user
     * @returns any
     * @throws ApiError
     */
    public static userControllerDeleteMe(): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/users/me`,
        });
    }

    /**
     * Change user password
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static userControllerChangeUserPassword(
        id: number,
        requestBody: ChangePasswordRequest,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/users/${id}/change-password`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Block user
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerBlockUser(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/users/${id}/block`,
        });
    }

    /**
     * Unblock user
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerUnblockUser(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/users/${id}/unblock`,
        });
    }

    /**
     * Resend verification email
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerResendVerificationEmail(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/users/${id}/resend-verification-email`,
        });
    }

    /**
     * Restores deleted user
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static userControllerRestoreUser(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/users/${id}/restore`,
        });
    }

    /**
     * Check password of currect user
     * @returns CheckPasswordDto
     * @throws ApiError
     */
    public static userControllerCheckMyPassword(body: CheckMyPasswordRequest): CancelablePromise<CheckPasswordDto> {
        return __request({
            method: 'POST',
            path: `/api/users/me/check-password`,
            mediaType: 'application/json',
            body
        });
    }

}