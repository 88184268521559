import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function CardIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="CardIcon">
            
<path d="M1.46875 4C0.659469 4 0 4.66826 0 5.48833V6.97667H23.5V5.48833C23.5 4.66826 22.842 4 22.0312 4H1.46875ZM0 11.4417V18.8833C0 19.7049 0.659469 20.3717 1.46875 20.3717H22.0312C22.842 20.3717 23.5 19.7049 23.5 18.8833V11.4417H0ZM3.67188 14.4183H6.60938C7.01475 14.4183 7.34375 14.7517 7.34375 15.1625C7.34375 15.5733 7.01475 15.9067 6.60938 15.9067H3.67188C3.2665 15.9067 2.9375 15.5733 2.9375 15.1625C2.9375 14.7517 3.2665 14.4183 3.67188 14.4183Z" />


        </SvgIcon>
    )
}
        