import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function PlusIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="PlusIcon">
            
  <path d="M19.25 8.75L11.75 8.75V1.25C11.75 0.918479 11.6183 0.600537 11.3839 0.366117C11.1495 0.131696 10.8315 0 10.5 0C10.1685 0 9.85054 0.131696 9.61612 0.366117C9.3817 0.600537 9.25 0.918479 9.25 1.25V8.75H1.75C1.41848 8.75 1.10054 8.8817 0.866117 9.11612C0.631696 9.35054 0.5 9.66848 0.5 10C0.5 10.3315 0.631696 10.6495 0.866117 10.8839C1.10054 11.1183 1.41848 11.25 1.75 11.25H9.25L9.25 18.75C9.25 19.0815 9.3817 19.3995 9.61612 19.6339C9.85054 19.8683 10.1685 20 10.5 20C10.8315 20 11.1495 19.8683 11.3839 19.6339C11.6183 19.3995 11.75 19.0815 11.75 18.75L11.75 11.25L19.25 11.25C19.5815 11.25 19.8995 11.1183 20.1339 10.8839C20.3683 10.6495 20.5 10.3315 20.5 10C20.5 9.66848 20.3683 9.35054 20.1339 9.11612C19.8995 8.8817 19.5815 8.75 19.25 8.75Z" />

        </SvgIcon>
    )
}
        