import React from "react";
import ReactDOM from "react-dom/client";
import { loadLocalization } from "locale";
import "react-datepicker/dist/react-datepicker.css";
import Providers from "./Providers";
import Router from "./Router";
import "./module/api/OpenApiConfig";
loadLocalization();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Providers>
            <Router />
        </Providers>
    </React.StrictMode>,
);
