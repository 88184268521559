import { createGlobalStyle } from "styled-components";
import "./fonts.css";
import { Theme, Toolbar } from "@peersyst/react-components";

export const CSSReset = createGlobalStyle<{ theme: Theme }>`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    scroll-behavior: smooth;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    color: #121212;
    font-family: "Work Sans", sans-serif;
    font-feature-settings: "clig" off, "liga" off;
    font-size: clamp(14px, 1.5vw, 16px);
  }

  * {
    box-sizing: border-box;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
  }

  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  strong {
    font-weight: 500;
  }

  #root {
      min-height: 100vh;
  }

  a {
      text-decoration: none;
  }

  .react-datepicker-wrapper input{
        width: 100%;
        padding: 1.5em;
        border-radius: 1em;
        border: 1px solid #d2d2d2;
    }

    .react-datepicker__day--selected{
      background-color: ${(p) => p.theme.palette.primary};
    }
    .react-multi-carousel-list{
      border-radius: 1em;
      .react-multiple-carousel__arrow{
        background: #fff;
        min-height: 70px;
        min-width: 67px;
        z-index: 1;
        margin-top: -10px;
        z-index:1;
        &--right{
              right: 0;
              border-radius : 5px 0px 0px 5px;
        }
        &--left{
              left: 0;
              border-radius : 0px 5px 5px 0px;
        }
        ${(p) => p.theme.breakpoints.down("mini")}{
          min-height: 40px;
          min-width: 40px;
        }
      }
      .react-multiple-carousel__arrow:before{
          color:${(p) => p.theme.palette.black}
      }
      .react-multiple-carousel__arrow:hover{
          background: ${(p) => p.theme.palette.primary};
      }
    }

    .react-multi-carousel-list.secondary{
      .react-multiple-carousel__arrow{
          background: #E8E8E8;
      }
    }
    .SliderRail{
      background-color: #E8E8E8;
      height: 0.6em;
    }
    
  ${Toolbar} {
      padding: 20px 40px;
      min-height: 72.5px;

      ${(p) => p.theme.breakpoints.down("mobile")} {
          padding: 20px 15px;
      }
  }

  :root {
      --spacing-8: 0.5rem;
      --spacing-16: 1rem;
      --spacing-24: 1.5rem;
      --spacing-32: 2rem;
      --spacing-40: 2.5rem;
      --spacing-48: 3rem;
      --spacing-56: 3.5rem;
      --spacing-64: 4rem;
      --spacing-72: 4.5rem;
      --spacing-80: 5rem;
      --spacing-88: 5.5rem;
      --spacing-96: 6rem;
  }
`;
