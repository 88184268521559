import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function LoginKeysIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="LoginKeysIcon">
            
<path fillRule="evenodd" clipRule="evenodd" d="M7.97965 10.5573C9.47269 11.9933 11.6433 12.3505 13.4192 11.6332L13.8365 11.9044L14.6212 11.8004L13.8925 12.7231L14.7897 13.5859L16.4504 13.4774L16.1317 14.689L16.903 15.4564L18.2663 15.3987L18.0142 16.4102L18.9922 17.3515L20.055 16.9167L20.6256 17.3554L20.3471 17.9606L20.414 18.0072L23.7458 18.1972L24 17.0401L15.8747 9.11361C16.6432 7.4103 16.2657 5.4833 14.7734 4.04851C12.835 2.18468 9.74183 2.13712 7.86972 3.93159C5.98704 5.73523 6.04201 8.69447 7.97965 10.5573ZM8.83868 6.86928C8.22015 6.27649 8.21395 5.34086 8.81132 4.76888C9.41282 4.19417 10.3854 4.20185 11.0016 4.79588C11.6201 5.39115 11.6369 6.33322 11.0377 6.90916C10.4396 7.48238 9.45463 7.46554 8.83868 6.86928Z" />
<path d="M7.1309 11.3713C5.97177 10.2581 5.31918 8.79108 5.29286 7.2421C5.28408 6.74989 5.36124 6.27303 5.47555 5.8088C4.45499 6.99934 4.05864 8.65756 4.59717 10.2759C5.23686 12.2081 6.99801 13.4806 8.91836 13.6246L9.15783 14.0477L9.90512 14.3008L8.81902 14.8022L9.20324 15.9618L10.7311 16.5959L9.87028 17.5338L10.1903 18.5534L11.4289 19.1021L10.7205 19.8904L11.1422 21.1577L12.2967 21.2389L12.5937 21.8805L12.0567 22.2942L12.0928 22.3636L14.9633 24L15.7428 23.0849L12.4997 13.0692C12.188 13.117 11.8722 13.1442 11.5507 13.1442C9.88938 13.1442 8.31919 12.514 7.1309 11.3713Z" />
<path d="M5.25737 0C2.35826 0 0 2.2639 0 5.04701C0 7.20662 1.43833 9.01422 3.42939 9.73038C3.40849 9.57332 3.38682 9.41776 3.37185 9.25476C3.35585 9.07194 3.35792 8.8973 3.35843 8.72241C1.93816 8.04763 0.94676 6.66833 0.94676 5.04726C0.94676 2.76553 2.88054 0.909126 5.25737 0.909126C7.63421 0.909126 9.56798 2.76528 9.56798 5.04726C9.56798 5.32223 9.53185 5.58729 9.4756 5.8469C9.51199 6.02426 9.62165 6.19915 9.9135 6.25315C10.1772 6.30196 10.3287 6.15531 10.4169 5.98834C10.4786 5.68365 10.5147 5.36979 10.5147 5.04726C10.5147 2.2639 8.15674 0 5.25737 0Z" />


        </SvgIcon>
    )
}
        