import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function CrossIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="CrossIcon">
            
<path fillRule="evenodd" clipRule="evenodd" d="M21.7334 3.5537C21.8179 3.46918 21.885 3.36885 21.9307 3.25843C21.9765 3.14801 22 3.02966 22 2.91013C22 2.79061 21.9765 2.67226 21.9307 2.56184C21.885 2.45142 21.8179 2.35109 21.7334 2.26657C21.6489 2.18206 21.5486 2.11502 21.4382 2.06928C21.3277 2.02354 21.2094 2 21.0899 2C20.9703 2 20.852 2.02354 20.7416 2.06928C20.6311 2.11502 20.5308 2.18206 20.4463 2.26657L12 10.7147L3.5537 2.26657C3.46918 2.18206 3.36885 2.11502 3.25843 2.06928C3.14801 2.02354 3.02966 2 2.91013 2C2.79061 2 2.67226 2.02354 2.56184 2.06928C2.45142 2.11502 2.35109 2.18206 2.26657 2.26657C2.18206 2.35109 2.11502 2.45142 2.06928 2.56184C2.02354 2.67226 2 2.79061 2 2.91013C2 3.02966 2.02354 3.14801 2.06928 3.25843C2.11502 3.36885 2.18206 3.46918 2.26657 3.5537L10.7147 12L2.26657 20.4463C2.09589 20.617 2 20.8485 2 21.0899C2 21.3312 2.09589 21.5627 2.26657 21.7334C2.43726 21.9041 2.66875 22 2.91013 22C3.15152 22 3.38301 21.9041 3.5537 21.7334L12 13.2853L20.4463 21.7334C20.617 21.9041 20.8485 22 21.0899 22C21.3312 22 21.5627 21.9041 21.7334 21.7334C21.9041 21.5627 22 21.3312 22 21.0899C22 20.8485 21.9041 20.617 21.7334 20.4463L13.2853 12L21.7334 3.5537V3.5537Z" />


        </SvgIcon>
    )
}
        