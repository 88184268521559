import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function DonwloadIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="DonwloadIcon">
            
<path d="M1.52424 24H23.1828C23.8291 24 24.3535 23.4802 24.3535 22.8388V9.31709C24.3535 8.67562 23.8291 8.15587 23.1828 8.15587H20.089C19.4427 8.15587 18.9183 8.67562 18.9183 9.31709C18.9183 9.95857 19.4427 10.4783 20.089 10.4783H22.0121V21.6775H2.69496V10.4783H4.61799C5.26436 10.4783 5.78871 9.95857 5.78871 9.31709C5.78871 8.67562 5.26436 8.15587 4.61799 8.15587H1.52424C0.877913 8.15587 0.353516 8.67562 0.353516 9.31709V22.8388C0.353516 23.4802 0.877913 24 1.52424 24Z" />
<path d="M12.3522 0C11.7058 0 11.1814 0.519752 11.1814 1.16123V15.5183L8.62729 12.9849C7.52425 11.924 5.90032 13.5292 6.97185 14.6269C6.97181 14.6269 11.5244 19.1426 11.5244 19.1426C11.9491 19.5789 12.7117 19.5984 13.1442 19.166C13.155 19.1561 17.7325 14.627 17.7325 14.627C18.8008 13.5301 17.1848 11.9234 16.077 12.9849C16.077 12.9849 13.5229 15.5183 13.5229 15.5183V1.16123C13.5229 0.519752 12.9985 0 12.3522 0Z" />


        </SvgIcon>
    )
}
        