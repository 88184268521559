import { AuthTokenStorage } from "module/auth/AuthTokenStorage";
import { useEffect, useState } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { useQueryClient } from "react-query";
import { UserService } from "module/api/service";
import { authState, UserType } from "module/auth/AuthState";

export function useLoad(): boolean {
    const [loading, setLoading] = useState(true);
    const queryClient = useQueryClient();
    const setAuthState = useSetRecoilState(authState);
    const resetAuthState = useResetRecoilState(authState);
    useEffect(() => {
        const load = async () => {
            const token = AuthTokenStorage.get();
            try {
                if (token) {
                    const data = await queryClient.fetchQuery("me", UserService.userControllerGetMe);
                    setAuthState({ token: token, isLogged: true, role: data.type as UserType });
                }
            } catch (e) {
                if ((e as any).body?.statusCode === 401) {
                    await AuthTokenStorage.clear();
                    resetAuthState();
                } else throw e;
            }
            // OTHER STUFF
            setLoading(false);
        };
        load();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return loading;
}
