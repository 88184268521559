/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResourceDto } from '../models/ResourceDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ResourceService {

    /**
     * Upload file and create resource
     * @param type
     * @param formData
     * @returns ResourceDto
     * @throws ApiError
     */
    public static resourceControllerCreate(
        type: string,
        formData: {
            file?: Blob;
        },
    ): CancelablePromise<ResourceDto> {
        return __request({
            method: 'POST',
            path: `/api/resources/${type}/upload`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Download resource related file
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static resourceControllerDownload(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/resources/download/${id}`,
        });
    }

    /**
     * Delete resource and related file
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static resourceControllerDelete(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/api/resources/${id}`,
        });
    }

    /**
     * Download resource related file
     * @param id
     * @returns any
     * @throws ApiError
     */
    public static publicResourceControllerDownload(
        id: number,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/public/resources/download/${id}`,
        });
    }

}