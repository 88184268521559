import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function LocationOutlinedIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="LocationOutlinedIcon">
            
<path d="M7.99935 1.33398C6.58486 1.33398 5.22831 1.89589 4.22811 2.89608C3.22792 3.89628 2.66602 5.25283 2.66602 6.66732C2.66602 10.2673 7.36602 14.334 7.56602 14.5073C7.68677 14.6106 7.84045 14.6674 7.99935 14.6674C8.15825 14.6674 8.31193 14.6106 8.43268 14.5073C8.66602 14.334 13.3327 10.2673 13.3327 6.66732C13.3327 5.25283 12.7708 3.89628 11.7706 2.89608C10.7704 1.89589 9.41384 1.33398 7.99935 1.33398ZM7.99935 13.1007C6.57935 11.7673 3.99935 8.89398 3.99935 6.66732C3.99935 5.60645 4.42078 4.58904 5.17092 3.83889C5.92107 3.08875 6.93848 2.66732 7.99935 2.66732C9.06021 2.66732 10.0776 3.08875 10.8278 3.83889C11.5779 4.58904 11.9993 5.60645 11.9993 6.66732C11.9993 8.89398 9.41935 11.774 7.99935 13.1007ZM7.99935 4.00065C7.47193 4.00065 6.95636 4.15705 6.51783 4.45007C6.0793 4.74308 5.7375 5.15956 5.53567 5.64683C5.33384 6.1341 5.28103 6.67028 5.38392 7.18756C5.48681 7.70484 5.74079 8.18 6.11373 8.55294C6.48667 8.92588 6.96183 9.17985 7.47911 9.28275C7.99639 9.38564 8.53257 9.33283 9.01984 9.131C9.50711 8.92916 9.92358 8.58737 10.2166 8.14884C10.5096 7.71031 10.666 7.19473 10.666 6.66732C10.666 5.96007 10.3851 5.2818 9.88497 4.7817C9.38487 4.2816 8.70659 4.00065 7.99935 4.00065ZM7.99935 8.00065C7.73564 8.00065 7.47785 7.92245 7.25859 7.77594C7.03932 7.62944 6.86843 7.4212 6.76751 7.17756C6.66659 6.93393 6.64019 6.66584 6.69163 6.4072C6.74308 6.14856 6.87007 5.91098 7.05654 5.72451C7.24301 5.53804 7.48059 5.41105 7.73923 5.3596C7.99787 5.30816 8.26596 5.33456 8.50959 5.43548C8.75323 5.5364 8.96147 5.70729 9.10797 5.92656C9.25448 6.14582 9.33268 6.40361 9.33268 6.66732C9.33268 7.02094 9.19221 7.36008 8.94216 7.61013C8.69211 7.86018 8.35297 8.00065 7.99935 8.00065Z" />


        </SvgIcon>
    )
}
        