import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function EmailOutlinedIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="EmailOutlinedIcon">
            
<path d="M12.6673 2.66602H3.33398C2.80355 2.66602 2.29484 2.87673 1.91977 3.2518C1.5447 3.62687 1.33398 4.13558 1.33398 4.66602V11.3327C1.33398 11.8631 1.5447 12.3718 1.91977 12.7469C2.29484 13.122 2.80355 13.3327 3.33398 13.3327H12.6673C13.1978 13.3327 13.7065 13.122 14.0815 12.7469C14.4566 12.3718 14.6673 11.8631 14.6673 11.3327V4.66602C14.6673 4.13558 14.4566 3.62687 14.0815 3.2518C13.7065 2.87673 13.1978 2.66602 12.6673 2.66602ZM12.394 3.99935L8.47398 7.91935C8.41201 7.98183 8.33828 8.03143 8.25704 8.06528C8.1758 8.09912 8.08866 8.11655 8.00065 8.11655C7.91264 8.11655 7.82551 8.09912 7.74427 8.06528C7.66303 8.03143 7.58929 7.98183 7.52732 7.91935L3.60732 3.99935H12.394ZM13.334 11.3327C13.334 11.5095 13.2637 11.6791 13.1387 11.8041C13.0137 11.9291 12.8441 11.9993 12.6673 11.9993H3.33398C3.15717 11.9993 2.9876 11.9291 2.86258 11.8041C2.73756 11.6791 2.66732 11.5095 2.66732 11.3327V4.93935L6.58732 8.85935C6.96232 9.23388 7.47065 9.44426 8.00065 9.44426C8.53065 9.44426 9.03898 9.23388 9.41398 8.85935L13.334 4.93935V11.3327Z" />


        </SvgIcon>
    )
}
        