import { lazy } from "react";
import { Route } from "react-router-dom";

export enum ProfileRoutes {
    PROFILE = "/profile",
}

const Profile = lazy(() => import("./Profile"));

export const ProfileRouter = (
    <>
        <Route path={ProfileRoutes.PROFILE} element={<Profile />} />
    </>
);
