import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function WhatsappFilledIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="WhatsappFilledIcon">
            
<path d="M13.6013 2.32644C12.1074 0.824899 10.1154 0 7.99428 0C3.62651 0 0.0684247 3.55809 0.0646233 7.92587C0.0646233 9.32478 0.429555 10.6857 1.1214 11.8907L0 16L4.20432 14.8976C5.36373 15.5286 6.6676 15.8632 7.99428 15.8632H7.99808C12.3659 15.8632 15.9239 12.3051 15.9277 7.93348C15.9239 5.81611 15.099 3.82419 13.6013 2.32644ZM7.99428 14.5213C6.80826 14.5213 5.64884 14.2019 4.63767 13.6013L4.39819 13.4569L1.90449 14.1107L2.56973 11.6778L2.41387 11.4269C1.75243 10.3778 1.40651 9.16512 1.40651 7.92207C1.40651 4.29556 4.36397 1.33809 7.99808 1.33809C9.75812 1.33809 11.4117 2.02613 12.6586 3.26919C13.9016 4.51604 14.5859 6.16964 14.5859 7.92968C14.5821 11.5676 11.6246 14.5213 7.99428 14.5213ZM11.6094 9.58708C11.4117 9.48824 10.4386 9.00927 10.2561 8.94084C10.0736 8.87622 9.94059 8.84201 9.81134 9.03968C9.67829 9.23735 9.29815 9.68591 9.18411 9.81516C9.07007 9.94821 8.95223 9.96341 8.75456 9.86458C8.55689 9.76574 7.91825 9.55667 7.16178 8.88002C6.57257 8.35543 6.17723 7.70539 6.05938 7.50772C5.94534 7.31005 6.04798 7.20361 6.14682 7.10478C6.23425 7.01734 6.34449 6.87289 6.44332 6.75885C6.54216 6.64481 6.57637 6.56118 6.64099 6.42813C6.70562 6.29508 6.67521 6.18104 6.62579 6.08221C6.57637 5.98337 6.18103 5.00642 6.01377 4.61107C5.85411 4.22333 5.69065 4.27655 5.56901 4.27275C5.45497 4.26515 5.32192 4.26515 5.18887 4.26515C5.05582 4.26515 4.84295 4.31456 4.66048 4.51224C4.47801 4.70991 3.96863 5.18888 3.96863 6.16584C3.96863 7.14279 4.67949 8.08173 4.77832 8.21478C4.87716 8.34783 6.17343 10.3474 8.16154 11.2065C8.63291 11.4117 9.00165 11.5334 9.29055 11.6246C9.76572 11.7767 10.1953 11.7539 10.5374 11.7044C10.9175 11.6474 11.7082 11.2255 11.8755 10.7617C12.0389 10.2979 12.0389 9.90259 11.9895 9.81896C11.9401 9.73533 11.8071 9.68591 11.6094 9.58708Z" />


        </SvgIcon>
    )
}
        