import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function MenuIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="MenuIcon">
            
<rect y="17" width="24" height="1.71429" rx="0.857143" />
<rect y="11" width="24" height="1.71429" rx="0.857143" />
<rect y="5" width="24" height="1.71429" rx="0.857143" />


        </SvgIcon>
    )
}
        