/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Kyc } from '../models/Kyc';
import type { KycTokenDto } from '../models/KycTokenDto';
import type { SimplifiedKyc } from '../models/SimplifiedKyc';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class KycService {

    /**
     * Get authenticated user kyc
     * @returns Kyc
     * @throws ApiError
     */
    public static kycControllerGetMe(): CancelablePromise<Kyc> {
        return __request({
            method: 'GET',
            path: `/api/kyc/me`,
        });
    }

    /**
     * Get authenticated user kyc token
     * @returns KycTokenDto
     * @throws ApiError
     */
    public static kycControllerGetToken(): CancelablePromise<KycTokenDto> {
        return __request({
            method: 'GET',
            path: `/api/kyc/token`,
        });
    }

    /**
     * Get user simplified kyc
     * @param id
     * @returns SimplifiedKyc
     * @throws ApiError
     */
    public static kycControllerGetUserSimplified(
        id: number,
    ): CancelablePromise<SimplifiedKyc> {
        return __request({
            method: 'GET',
            path: `/api/kyc/${id}`,
        });
    }

    /**
     * Simulate kyc success
     * @returns any
     * @throws ApiError
     */
    public static kycTestControllerSimulateSuccess(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/kyc/success`,
        });
    }

    /**
     * Simulate kyc failure
     * @returns any
     * @throws ApiError
     */
    public static kycTestControllerSimulateFailure(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/kyc/failure`,
        });
    }

}