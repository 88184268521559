import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ToInvestIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ToInvestIcon">
            
<path d="M12.9342 7.41837H15.4276V15.429H7.41769V12.9354H11.1889L-4.02331e-07 1.7455L1.74534 0L12.9342 11.1899V7.41837Z" />
<path d="M1.77809 10.223H4.27143V21.5064H21.5067V4.26964H10.2243V1.77607H24V24H1.77809V10.223Z" />


        </SvgIcon>
    )
}
        