import { lazy } from "react";
import { Route } from "react-router-dom";

export enum PlatformGuestRoutes {
    OWNER = "/guest/owner",
    INVESTOR = "/guest/investor",
    PROJECT_PROPERTY = "/guest/project-property/:id",
    PROPERTY_TYPE = "/guest/property-type/:id",
    PROPERTY_TYPE_IMAGES = "/guest/property-type/:id/images",
    PROPERTIES = "/guest/properties",
    PROJECT_INVESTOR = "/guest/project-investor/:id",
    CONTRACTS = "/guest/contracts",
}

const ProjectProperty = lazy(() => import("./ProjectProperty"));
const PropertyType = lazy(() => import("./PropertyType"));
const PropertyTypeImages = lazy(() => import("./PropertyTypeImages"));
const Projects = lazy(() => import("./Projects"));
const Properties = lazy(() => import("./Properties"));
const Contracts = lazy(() => import("./Contracts"));

export const PlatformGuestRouter = (
    <>
        <Route path={PlatformGuestRoutes.OWNER} element={<Projects isLogged={false} type={"property"} />} />
        <Route path={PlatformGuestRoutes.INVESTOR} element={<Projects isLogged={false} type={"investment"} />} />
        <Route path={PlatformGuestRoutes.PROJECT_PROPERTY} element={<ProjectProperty isLogged={false} type={"property"} />} />
        <Route path={PlatformGuestRoutes.PROPERTY_TYPE_IMAGES} element={<PropertyTypeImages />} />
        <Route path={PlatformGuestRoutes.PROPERTY_TYPE} element={<PropertyType isLogged={false} />} />
        <Route path={PlatformGuestRoutes.PROPERTIES} element={<Properties type={"property"} />} />
        <Route path={PlatformGuestRoutes.PROJECT_INVESTOR} element={<ProjectProperty isLogged={false} type={"investment"} />} />
        <Route path={PlatformGuestRoutes.CONTRACTS} element={<Contracts />} />
    </>
);
