import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function ProfileIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="ProfileIcon">
            
<path d="M20.5648 16.5654C22.0353 15.4084 23.1087 13.8218 23.6356 12.0263C24.1625 10.2309 24.1166 8.31583 23.5044 6.54763C22.8922 4.77944 21.7441 3.24602 20.2199 2.16071C18.6956 1.07541 16.8709 0.492188 14.9998 0.492188C13.1286 0.492187 11.3039 1.07541 9.77965 2.16071C8.25537 3.24602 7.10727 4.77944 6.49507 6.54763C5.88287 8.31583 5.83702 10.2309 6.36389 12.0263C6.89077 13.8218 7.96416 15.4084 9.43475 16.5654C6.91487 17.575 4.71618 19.2495 3.07309 21.4103C1.43 23.5712 0.404095 26.1374 0.104751 28.8354C0.0830828 29.0324 0.100426 29.2317 0.155789 29.422C0.211152 29.6123 0.303452 29.7898 0.427418 29.9444C0.677779 30.2567 1.04193 30.4567 1.43975 30.5004C1.83758 30.5442 2.23649 30.4281 2.54874 30.1778C2.86099 29.9274 3.06099 29.5633 3.10475 29.1654C3.43413 26.2332 4.83228 23.5251 7.03208 21.5586C9.23188 19.5921 12.0791 18.505 15.0298 18.505C17.9804 18.505 20.8276 19.5921 23.0274 21.5586C25.2272 23.5251 26.6254 26.2332 26.9548 29.1654C26.9955 29.534 27.1714 29.8744 27.4484 30.121C27.7254 30.3675 28.0839 30.5027 28.4548 30.5004H28.6198C29.013 30.4552 29.3723 30.2564 29.6196 29.9473C29.8668 29.6382 29.9819 29.244 29.9397 28.8504C29.639 26.1447 28.6076 23.5719 26.956 21.4077C25.3045 19.2436 23.0951 17.5696 20.5648 16.5654ZM14.9998 15.5004C13.8131 15.5004 12.653 15.1485 11.6663 14.4892C10.6796 13.83 9.9106 12.8929 9.45647 11.7965C9.00235 10.7002 8.88353 9.49378 9.11504 8.32989C9.34655 7.166 9.91799 6.0969 10.7571 5.25779C11.5962 4.41867 12.6653 3.84723 13.8292 3.61572C14.9931 3.38421 16.1995 3.50303 17.2959 3.95715C18.3922 4.41128 19.3293 5.18031 19.9886 6.16701C20.6479 7.1537 20.9998 8.31374 20.9998 9.50043C20.9998 11.0917 20.3676 12.6179 19.2424 13.7431C18.1172 14.8683 16.5911 15.5004 14.9998 15.5004Z" />


        </SvgIcon>
    )
}
        