import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function Wallet2Icon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="Wallet2Icon">
            
<path d="M14.8327 3.83333H13.9993V3C13.9993 2.33696 13.736 1.70107 13.2671 1.23223C12.7983 0.763392 12.1624 0.5 11.4993 0.5H3.16602C2.50297 0.5 1.86709 0.763392 1.39825 1.23223C0.929408 1.70107 0.666016 2.33696 0.666016 3V13C0.666016 13.663 0.929408 14.2989 1.39825 14.7678C1.86709 15.2366 2.50297 15.5 3.16602 15.5H14.8327C15.4957 15.5 16.1316 15.2366 16.6004 14.7678C17.0693 14.2989 17.3327 13.663 17.3327 13V6.33333C17.3327 5.67029 17.0693 5.03441 16.6004 4.56557C16.1316 4.09673 15.4957 3.83333 14.8327 3.83333ZM3.16602 2.16667H11.4993C11.7204 2.16667 11.9323 2.25446 12.0886 2.41074C12.2449 2.56702 12.3327 2.77899 12.3327 3V3.83333H3.16602C2.945 3.83333 2.73304 3.74554 2.57676 3.58926C2.42048 3.43298 2.33268 3.22101 2.33268 3C2.33268 2.77899 2.42048 2.56702 2.57676 2.41074C2.73304 2.25446 2.945 2.16667 3.16602 2.16667ZM15.666 10.5H14.8327C14.6117 10.5 14.3997 10.4122 14.2434 10.2559C14.0871 10.0996 13.9993 9.88768 13.9993 9.66667C13.9993 9.44565 14.0871 9.23369 14.2434 9.07741C14.3997 8.92113 14.6117 8.83333 14.8327 8.83333H15.666V10.5ZM15.666 7.16667H14.8327C14.1696 7.16667 13.5338 7.43006 13.0649 7.8989C12.5961 8.36774 12.3327 9.00362 12.3327 9.66667C12.3327 10.3297 12.5961 10.9656 13.0649 11.4344C13.5338 11.9033 14.1696 12.1667 14.8327 12.1667H15.666V13C15.666 13.221 15.5782 13.433 15.4219 13.5893C15.2657 13.7455 15.0537 13.8333 14.8327 13.8333H3.16602C2.945 13.8333 2.73304 13.7455 2.57676 13.5893C2.42048 13.433 2.33268 13.221 2.33268 13V5.35833C2.60041 5.45251 2.88221 5.50042 3.16602 5.5H14.8327C15.0537 5.5 15.2657 5.5878 15.4219 5.74408C15.5782 5.90036 15.666 6.11232 15.666 6.33333V7.16667Z" />

        </SvgIcon>
    )
}
        