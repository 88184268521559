import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function RisingChartIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="RisingChartIcon">
            
<path d="M1.83268 10.5L0.666016 9.33333L6.83268 3.125L10.166 6.45833L14.4993 2.16667H12.3327V0.5H17.3327V5.5H15.666V3.33333L10.166 8.83333L6.83268 5.5L1.83268 10.5Z" />

        </SvgIcon>
    )
}
        