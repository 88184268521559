import React from "react";
import { SvgIcon, SvgIconProps } from "@peersyst/react-components"
export function VerifiedIcon (props: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...props} data-testid="VerifiedIcon">
            
<path d="M23.9995 11.9983L21.2826 8.98172L21.708 4.94343L17.7352 4.10239L15.7086 0.581909L11.9996 2.2396L11.4989 11.9983L11.9996 21.7568L15.7087 23.4145L17.7352 19.8941L21.708 19.053L21.2826 15.0147L23.9995 11.9983Z" />
<path d="M8.29084 0.581909L6.26438 4.10239L2.29152 4.94347L2.71691 8.98172L0 11.9983L2.71691 15.0147L2.29152 19.053L6.26438 19.8941L8.29092 23.4145L12 21.7568V2.2396L8.29084 0.581909Z" />
<path d="M17.0318 9.37184L15.9814 8.43427L12.0012 12.8935L11.5005 14.0018L12.0012 15.0079L17.0318 9.37184Z" />
<path d="M7.81666 10.9397L6.82114 11.9352L11.0068 16.121L11.9997 15.0086V12.8942L10.9487 14.0716L7.81666 10.9397Z" />


        </SvgIcon>
    )
}
        